<template>
  <div class="relative">
    <button
      class="order-1 relative w-10 h-10 flex items-center justify-center bg-primary-medium border-0 rounded-lg text-primary-muted cursor-pointer hover:bg-primary-main hover:text-white active:brightness-95"
      @click.prevent="toggleNotifications"
    >
      <i class="icon-bell text-2xl"></i>
      <span v-if="unreadCount > 0" class="absolute top-0 right-0 translate-x-0.5 -translate-y-0.5 w-2.5 h-2.5 rounded-full block bg-primary-bright"></span>
    </button>

    <OnClickOutside @trigger="closeNotifications">
      <div
        class="absolute top-full left-1/2 -translate-x-2/3 sm:translate-x-0 sm:left-0 w-[280px] mt-1 py-2 flex flex-col rounded-lg shadow shadow-black bg-primary-medium z-20 text-sm font-medium"
        v-if="showNotificationsDropdown"
      >
        <ul class="overflow-y-auto max-h-60">
          <li v-for="notification in displayedNotifications" :key="notification.id">
            <a
              class="px-4 py-2 block no-underline text-primary-muted bg-primary-medium hover:bg-primary-light/20"
              href="#"
              @click.prevent="navigateToSignal(notification)"
              v-if="notification.type === 'signal'"
            >
              <div class="text-sm text-white flex flex-wrap items-center justify-between gap-1">
                <div>
                  <i :class="arrowClass(notification.data.direction)"></i>
                  {{ getSignalTitle(notification) }}
                </div>
                <span v-if="!notification.read" class="text-xs bg-primary-bright text-white px-2 rounded-full">New</span>
              </div>
              <div class="text-sm truncate">{{ notification.data ? notification.data?.description : notification.message }}</div>
              <div class="text-xs uppercase">{{ formatDate(notification.createdAt) }}</div>
            </a>
          </li>
          <li v-if="notifications.length === 0" class="px-4 py-2 text-white">No notifications</li>
        </ul>
        <div class="px-4 py-2 flex justify-between items-center">
          <button @click="clearAllNotifications" class="text-red-500 text-xs">Clear All</button>
          <button v-if="notifications.length > 6" @click="showMoreNotifications" class="text-primary-muted text-xs">Show More</button>
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>
<script>
import { OnClickOutside } from '@vueuse/components';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useToast } from 'vue-toastification';
import {
  getNotifications, markNotificationAsRead, saveFCMToken, clearAllNotificationsAPI,
} from '../api';

export default {
  components: {
    OnClickOutside,
  },
  data() {
    return {
      notifications: [],
      showNotificationsDropdown: false,
      messaging: getMessaging(),
      visibleCount: 6, // Number of notifications to display initially
    };
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  computed: {
    unreadCount() {
      return this.notifications.filter((notification) => !notification.read).length;
    },
    displayedNotifications() {
      return this.notifications.slice(0, this.visibleCount);
    },
  },
  mounted() {
    this.loadNotifications();
    this.checkNotificationPermission();
    this.listenForMessages();
  },
  methods: {
    arrowClass(direction) {
      if (direction === 1) {
        return 'fa-solid fa-arrow-trend-up text-green-600';
      } if (direction === -1) {
        return 'fa-solid fa-arrow-trend-down text-red-600';
      }
      return '';
    },
    getSignalTitle(notification) {
      const title = `${notification.data.ticker} - ${notification.title}`;
      return title;
    },
    async loadNotifications() {
      try {
        this.notifications = await getNotifications();
      } catch (error) {
        console.error('Failed to load notifications:', error);
      }
    },
    navigateToSignal(notification) {
      this.$router.push({ name: 'signal-detail', params: { id: notification.relatedId } });
      this.markAsRead(notification._id);
      this.closeNotifications();
    },
    async markAsRead(notificationId) {
      try {
        await markNotificationAsRead(notificationId);
        this.notifications = this.notifications.map((notification) => (notification._id === notificationId ? { ...notification, read: true } : notification));
      } catch (error) {
        console.error('Failed to mark notification as read:', error);
      }
    },
    toggleNotifications() {
      this.showNotificationsDropdown = !this.showNotificationsDropdown;
    },
    closeNotifications() {
      this.showNotificationsDropdown = false;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    showMoreNotifications() {
      this.visibleCount += 6; // Increase the number of visible notifications by 6
    },
    async clearAllNotifications() {
      try {
        await clearAllNotificationsAPI();
        this.notifications = [];
        this.visibleCount = 6; // Reset visible count
        this.closeNotifications();
      } catch (error) {
        console.error('Failed to clear notifications:', error);
      }
    },
    checkNotificationPermission() {
      if (Notification.permission === 'granted') {
        console.log('Notification permission is already granted.');
        this.requestNotificationPermission();
      } else if (Notification.permission !== 'denied') {
        this.requestNotificationPermission();
      } else {
        console.log('Notification permission is denied.');
      }
    },
    async requestNotificationPermission() {
      try {
        const token = await getToken(this.messaging, { vapidKey: 'BPqHjGXaibx4qzAb9vjW8d5dBXWXWvTWKLKVlRT-KZeR393FKzWt89GMKhJPgVXfwe7I6hPUzhx_l2mcdzcwS2k' });
        if (token) {
          await saveFCMToken(token);
          console.log('FCM Token:', token);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      } catch (error) {
        console.error('An error occurred while retrieving token. ', error);
      }
    },
    listenForMessages() {
      console.log('Starting listening ');
      onMessage(this.messaging, (payload) => {
        console.log('Message received. ', payload);
        this.toast.info('New notification received');
      });
    },
  },
};
</script>
<style scoped>
.top-3 {
  top: 1.2rem;
}
</style>
