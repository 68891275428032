// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCwzOtcrwpxLLPD8GAstEnbUOj22goVlN0',
  authDomain: 'thaurusguru.firebaseapp.com',
  projectId: 'thaurusguru',
  storageBucket: 'thaurusguru.appspot.com',
  messagingSenderId: '678213818828',
  appId: '1:678213818828:web:8b1398815bf83f2382a569',
  measurementId: 'G-WBBVCHLVTV',
};

export default firebaseConfig;
