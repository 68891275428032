<template>
<div class="signal-value w-full pl-5 flex flex-col justify-center" v-if="event.now">
  <span>Now</span>
</div>
   <router-link class="p-3 lg:p-5 border border-primary-main rounded-xl flex flex-wrap lg:flex-nowrap gap-2 lg:gap-6 hover:bg-primary-medium"
          :to="{ name: 'event-detail', params: { id: event._id } }"
        >
          <div class="w-full lg:w-1/4 flex-shrink-0">
            <div class="flex items-center gap-1">
              <div class="lg:w-2/5 flex items-center flex-shrink-0">
                <i class="icon-angle-right -ml-1 text-xl"></i>
                <span>{{ getTime(event.date) }}</span>
              </div>
              <div class="flex-grow text-center">
                <i class="icon-bull-big text-4xl" :style="getColor(event)"></i>
              </div>
              <country-flag :country="event.country" size='normal'/>
              <div class="w-6 flex"><img class="w-6 rounded" :src="event.flagUrl" alt=""></div>
            </div>
          </div>
          <div class="signal-value w-full pl-5 flex flex-col justify-center">
            <span>{{ event.title }}</span>
          </div>
          <div class="w-full lg:w-2/5 flex-shrink-0">
            <div class="grid grid-cols-3 gap-1">
              <div class="signal-value pl-5 flex flex-col text-primary-muted">
                <div class="text-xs">forecast</div>
                <div class="flex items-center">
                  <i class="icon-angle-right -ml-1 text-xl"></i>
                  <span v-if="event.forecast" :style="getValueColor(event.forecast)">{{ event.forecast }}{{ event.unit }}</span>
                </div>
              </div>
              <div class="signal-value pl-5 flex flex-col text-primary-muted">
                <div class="text-xs">actual</div>
                <div class="flex items-center">
                  <i class="icon-angle-right -ml-1 text-xl"></i>
                  <span v-if="event.actual" :style="getValueColor(event.actual)">{{ event.actual }}{{ event.unit }}</span>
                </div>
              </div>
              <div class="signal-value pl-5 flex flex-col text-primary-muted">
                <div class="text-xs">prev</div>
                <div class="flex items-center">
                  <i class="icon-angle-right -ml-1 text-xl"></i>
                  <span v-if="event.previous" :style="getValueColor(event.previous)">{{ event.previous }}{{ event.unit }}</span>
                </div>
              </div>
            </div>
          </div>

        </router-link>
</template>
<script>
export default {
  props: {
    event: Object,
  },
  methods: {
    getTime(date) {
      console.log(date);
      return new Date(date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    },
    getColor(event) {
      // if indicator is string holiday or holidays, return grey
      if (event.indicator === 'holiday' || event.indicator === 'holidays') {
        return 'color: grey';
      }

      // 1: red, 0: orange, -1: yellow
      if (event.importance === 1) {
        return 'color: red';
      } if (event.importance === 0) {
        return 'color: orange';
      }
      return 'color: yellow';
    },
    getValueColor(value) {
      // if value is positive return green, if its 0 then grey, else red
      if (value > 0) {
        return 'color: green';
      } if (value === 0) {
        return 'color: grey';
      }
      return 'color: red';
    },
  },
};
</script>
