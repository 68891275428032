<template>
  <div class="flex-grow">
    <div class="my-3 lg:my-7">
      <h3 class="py-1 my-px font-bold">{{ $t('calendar.title') }}</h3>
      <div class="px-3 lg:px-5 py-2 flex items-center bg-primary-medium rounded-xl">
        <div class="flex min-w-px items-center gap-3 lg:gap-4 flex-shrink-0">
          <img src="../images/calendar.svg" alt="">
          <div class="text-xs lg:text-sm">
            <div>{{ $t('calendar.eventsFor') }}</div>
            <div>{{ $t('calendar.adjacentDates') }}</div>
          </div>
        </div>
        <div class="w-full flex-grow min-w-px">
          <div class="w-full overflow-x-auto" data-simplebar>
            <div class="flex items-center uppercase">
              <div v-for="(date, index) in dates" :key="index" class="calendar-item px-2 lg:px-5 flex-shrink-0">
                <a
                  :class="['px-2 lg:px-4 py-1 lg:py-2 rounded-lg flex flex-col items-center', { 'bg-primary-bright text-white': date.isToday, 'text-primary-muted hover:bg-primary-bright hover:text-white': !date.isToday }]"
                  href="#"
                  @click.prevent="selectDay(index)"
                >
                  <span class="text-base lg:text-lg font-medium text-white">{{ date.day }}</span>
                  <span class="text-xs lg:text-sm">{{ date.weekday }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <button class="px-4 py-2 border border-primary-main rounded-lg text-primary-main hover:bg-primary-main hover:text-white" @click="loadPrevious">{{ $t('calendar.showAll') }}</button>
    </div>
    <div class="my-3 lg:my-4">
      <div class="mt-4 lg:mt-5 flex flex-col gap-1">
        <EventComp v-for="(event, index) in events" :key="index" :event="event" />
      </div>
    </div>
    <div class="flex justify-center">
      <button class="px-4 py-2 border border-primary-main rounded-lg text-primary-main hover:bg-primary-main hover:text-white" @click="loadMore">{{ $t('calendar.loadMore') }}</button>
    </div>
  </div>
</template>

<script>
import * as api from '../api';
import EventComp from '../components/event/EventComp.vue';

export default {
  components: {
    EventComp,
  },
  data() {
    return {
      dates: [],
      selectedDateIndex: 1,
      events: [],
      page: 1,
      limit: 10,
    };
  },
  created() {
    this.generateDates();
    this.fetchEvents(true);
    console.log('created');
  },
  methods: {
    async fetchEvents(firstime) {
      const selectedDate = this.dates[this.selectedDateIndex];

      if (firstime) {
        // fetch firstPageKey
        this.page = await api.getFirstPageKey(this.limit, selectedDate.date);
      }
      console.log(firstime);

      this.events = await api.getEvents(this.page, this.limit, selectedDate.date, firstime);

      if (firstime) {
        this.events[0].now = true;
      }

      console.log(this.events);
    },
    getTime(date) {
      // date is string '2024-08-03T00:00:00.000Z'
      const time = new Date(date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
      return time;
    },
    generateDates() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // add yesterday's date
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      // clear time
      yesterday.setHours(0, 0, 0, 0);
      this.dates.push({
        date: yesterday,
        day: yesterday.getDate(),
        weekday: yesterday.toLocaleString('en-US', { weekday: 'short' }),
        isToday: false,
      });

      // clear time
      today.setHours(0, 0, 0, 0);
      this.dates.push({
        date: today,
        day: today.getDate(),
        weekday: today.toLocaleString('en-US', { weekday: 'short' }),
        isToday: true,
      });

      // add next 6 days
      for (let i = 1; i < 7; i += 1) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        date.setHours(0, 0, 0, 0);
        this.dates.push({
          date,
          day: date.getDate(),
          weekday: date.toLocaleString('en-US', { weekday: 'short' }),
          isToday: false,
        });
      }
    },
    selectDay(index) {
      this.page = 1;
      this.dates[this.selectedDateIndex].isToday = false;
      this.selectedDateIndex = index;
      this.dates[this.selectedDateIndex].isToday = true;
      this.fetchEvents();
    },
    async loadPrevious() {
      this.page = 1;
      this.fetchEvents();
    },
    async loadMore() {
      this.page += 1;
      const selectedDate = this.dates[this.selectedDateIndex];
      this.events = [...this.events, ...await api.getEvents(this.page, this.limit, selectedDate.date)];
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles */
</style>
