<template>
  <div class="flex-grow">
    <div class="my-3 lg:my-7">
      <div class="flex flex-wrap items-center justify-between gap-1">
        <h3 class="py-1 my-px font-bold">{{ $t('news.title') }}</h3>

        <div class="w-full lg:w-auto overflow-x-auto lg:overflow-x-visible flex gap-2 text-sm font-semibold">
          <a
            class="px-4 py-1.5 border rounded-lg"
            :class="{
              'bg-primary-main border-primary-main': selectedCategory === 'all',
              'border-primary-light hover:bg-primary-main hover:border-primary-main': selectedCategory !== 'all'
            }"
            href="#"
            @click.prevent="selectCategory('all')"
          >{{ $t('news.categories.all') }}</a>
          <a
            class="px-4 py-1.5 border rounded-lg"
            :class="{
              'bg-primary-main border-primary-main': selectedCategory === 'forex',
              'border-primary-light hover:bg-primary-main hover:border-primary-main': selectedCategory !== 'forex'
            }"
            href="#"
            @click.prevent="selectCategory('forex')"
          >{{ $t('news.categories.forex') }}</a>
          <a
            class="px-4 py-1.5 border rounded-lg"
            :class="{
              'bg-primary-main border-primary-main': selectedCategory === 'stock',
              'border-primary-light hover:bg-primary-main hover:border-primary-main': selectedCategory !== 'stock'
            }"
            href="#"
            @click.prevent="selectCategory('stock')"
          >{{ $t('news.categories.stock') }}</a>
          <a
            class="px-4 py-1.5 border rounded-lg"
            :class="{
              'bg-primary-main border-primary-main': selectedCategory === 'crypto',
              'border-primary-light hover:bg-primary-main hover:border-primary-main': selectedCategory !== 'crypto'
            }"
            href="#"
            @click.prevent="selectCategory('crypto')"
          >{{ $t('news.categories.crypto') }}</a>
          <a
            class="px-4 py-1.5 border rounded-lg"
            :class="{
              'bg-primary-main border-primary-main': selectedCategory === 'base',
              'border-primary-light hover:bg-primary-main hover:border-primary-main': selectedCategory !== 'base'
            }"
            href="#"
            @click.prevent="selectCategory('base')">{{ $t('news.categories.commodities') }}</a>
        </div>
      </div>
      <div class="mt-4 flex flex-col gap-4" v-if="loadingNews">
        <Loader />
      </div>
      <div class="mt-4 flex flex-col gap-4" v-if="!loadingNews">
        <div class="flex items-center gap-4" v-for="item in news" :key="item._id">
          <router-link
            :to="{ name: 'news-detail', params: { id: item._id } }"
            class="group relative w-20 h-20 flex-shrink-0 bg-primary-medium rounded-xl overflow-hidden"
            href=""
          >
            <img
              class="w-full h-full block object-cover transition-all group-hover:scale-105"
              :src="item.thumbnailUrl"
              :srcset="`${item.thumbnailUrl} 1x, ${item.thumbnailUrl} 2x`"
              alt=""
            >
          </router-link>
          <div class="flex-grow">
            <router-link
              :to="{ name: 'news-detail', params: { id: item._id } }"
              class="line-clamp-2 hover:text-primary-bright"
              href=""
            >{{ item.title }}</router-link>
            <div class="mt-1 text-xs text-primary-muted">{{ getFormattedDate(item.published) }} {{ getFormattedTime(item.published) }} / <a class="text-primary-main underline hover:no-underline" href="">{{ item.source }}</a></div>
          </div>
        </div>
        <div class="flex justify-center">
          <button class="px-4 py-2 border border-primary-main rounded-lg text-primary-main hover:bg-primary-main hover:text-white" @click="loadMore">{{ $t('news.loadMore') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '../api';
import Loader from '../components/LoaderComp.vue';

export default {
  name: 'NewsScreen',
  data() {
    return {
      news: [],
      page: 1,
      selectedCategory: 'all',
      loadingNews: false,
    };
  },
  components: {
    Loader,
  },
  async created() {
    this.loadingNews = true;
    const params = {
      page: this.page,
      limit: 8,
    };

    this.news = await api.getNews(params);
    this.loadingNews = false;
  },
  methods: {
    async selectCategory(category) {
      this.selectedCategory = category;
      const params = {
        page: 1,
        limit: 8,
        category: category === 'all' ? undefined : category,
      };

      this.news = await api.getNews(params);
    },
    async loadMore() {
      this.page += 1;
      const params = {
        page: this.page,
        limit: 6,
      };

      this.news = [...this.news, ...await api.getNews(params)];
    },
    getFormattedDate(date) {
      // if date is timestamp, convert it to ISO string
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      // return format like Apr 28, 2024
      return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    },
    getFormattedTime(date) {
      // if date is timestamp, convert it to ISO string
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      // return format like 03:56 AM
      return new Date(date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    },
  },
};
</script>
