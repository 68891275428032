<template>
  <div class="flex flex-col lg:flex-row gap-3 lg:gap-7 flex-grow">
    <div class="px-4 py-4 lg:py-6 h-[650px] overflow-y-auto">
      <div class="flex flex-col gap-3 lg:gap-6">
        <div class="mb-3 text-sm text-primary-muted">
          <div class="lg-3">
            <div class="flex items-center mb-2">
              <ToggleSwitch
                class="mb-1"
                v-model="instrumental"
                @change="toggleInstrumental"
              />
              <h1 class="mb-1 block px-1">Instrumental</h1>
            </div>
            <div class="flex items-center justify-between">
              <h1 class="mb-1 block text-white font-bold" v-if="!instrumental">
                {{ $t('songBoard.lyrics') }}
              </h1>
              <div class="text-right"></div>
            </div>

            <div
              class="mb-2.5 p-1 bg-primary-medium rounded rounded-xl"
              v-if="!instrumental"
            >
              <textarea
                type="text"
                rows="10"
                maxlength="3000"
                class="bg-primary-medium border-active-0 border-0 w-full px-4 bg-[#E6E7E8] text-white rounded-lg text-sm focus:ring-0 focus:ring-primary-muted focus:border-primary-muted placeholder:text-white"
                :placeholder="$t('songBoard.lyricsPlaceholder')"
                v-model="lyrics"
              ></textarea>
              <!-- <div class="input-errors" v-for="error of v$.password.old.$errors" :key="error.$uid">
                  <div class="error-msg">{{ $t(error.$message) }}</div>
                </div> -->
              <!-- remaining number of characters like 10/2000 -->

              <div class="p-1 text-right text-primary-muted text-xs">
                {{ lyrics?.length ?? 0 }}/3000
              </div>
            </div>
            <h1 class="mb-1 block text-white font-bold">
              {{ $t('songBoard.style') }}
            </h1>
            <div class="mb-2.5 p-1 bg-primary-medium rounded rounded-xl">
              <textarea
                rows="4"
                type="password"
                maxlength="120"
                v-model="style"
                class="no-resize bg-primary-medium border-active-0 border-0 w-full px-4 bg-[#E6E7E8] text-white rounded-lg text-sm focus:ring-0 focus:ring-primary-muted focus:border-primary-muted placeholder:text-white"
                :placeholder="$t('songBoard.stylePlaceholder')"
              >
              </textarea>
              <div class="p-1 text-right text-primary-muted text-xs">
                {{ style?.length ?? 0 }}/120
              </div>
              <!-- <div class="input-errors" v-for="error of v$.password.new.$errors" :key="error.$uid">
                  <div class="error-msg">{{ $t(error.$message) }}</div>
                </div> -->
            </div>
            <h1 class="mb-1 block text-white font-bold">
              {{ $t('songBoard.title') }}
            </h1>
            <div class="mb-2.5 p-1 bg-primary-medium rounded rounded-xl">
              <textarea
                type="text"
                rows="1"
                maxlength="80"
                v-model="title"
                class="no-resize bg-primary-medium border-active-0 border-0 w-full px-4 bg-[#E6E7E8] text-white rounded-lg text-sm focus:ring-0 focus:ring-primary-muted focus:border-primary-muted placeholder:text-white"
                :placeholder="$t('songBoard.titlePlaceholder')"
              >
              </textarea>
              <div class="text-right text-primary-muted text-xs">
                {{ title?.length ?? 0 }}/80
              </div>
              <!-- <div class="input-errors" v-for="error of v$.password.confirm.$errors" :key="error.$uid">
                  <div class="error-msg">{{ $t(error.$message) }}</div>
                </div> -->
            </div>
            <div class="flex flex-wrap items-center justify-between gap-3">
              <button
                class="mt-2.5 h-10 px-7 bg-transparent inline-flex border border-primary-main items-center justify-center rounded-lg text-white font-semibold hover:brightness-110 active:brightness-95"
                @click.prevent="clear"
              >
                {{ $t('songBoard.clear') }}
              </button>
              <button
                class="mt-2.5 h-10 px-7 bg-primary-main inline-flex items-center justify-center rounded-lg text-white font-semibold hover:brightness-110 active:brightness-95"
                @click="createSong"
              >
                {{ $t('songBoard.createSong') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow h-[650px] overflow-y-auto">
      <div class="flex flex-wrap items-center justify-between gap-1">
        <h1 class="py-1 my-px font-bold">Songs</h1>
      </div>
      <div class="mt-4 lg:mt-5 flex flex-col gap-1">
        <SongComp v-for="(song, index) in songs" :key="index" :song="song" />
        <div
          v-if="songs.length === 0"
          class="p-2 lg:p-4 border border-primary-light rounded-xl text-center"
        >
          No Songs yet
        </div>
      </div>
    </div>
    <!-- <div
          v-if="signals.length > 0"
          class="flex justify-center mt-4" >
            <button class="px-4 py-2 border border-primary-main rounded-lg text-primary-main hover:bg-primary-main hover:text-white" @click="loadMore">Load more</button>
          </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
import ToggleSwitch from 'primevue/toggleswitch';
import * as api from '../../api';
import SongComp from './songs/SongComp.vue';

export default {
  data() {
    return {
      lyrics: null,
      style: null,
      title: null,
      songs: [],
      instrumental: false,
      creating: false,
    };
  },
  components: {
    SongComp,
    ToggleSwitch,
  },
  mounted() {
    this.fetchSongs();
  },
  methods: {
    async fetchSongs() {
      try {
        this.songs = await api.fetchSongs();
      } catch (error) {
        console.error(error);
      }
    },
    changePassword() {
      console.log('change password');
    },
    clear() {
      this.lyrics = null;
      this.style = null;
      this.title = null;
    },
    toggleInstrumental() {
      this.lyrics = null;
    },
    async createSong() {
      try {
        this.creating = true;
        await api.createSong({
          lyrics: this.lyrics,
          style: this.style,
          title: this.title,
          instrumental: this.instrumental,
        });
        this.fetchSongs();
        this.clear();
      } catch (error) {
        console.error(error);
      } finally {
        this.creating = false;
      }
    },
  },
};
</script>
<style>
.no-resize {
  resize: none;
  border: none;
  outline: none;
}
.h-\[650px\] {
  height: 650px;
}
/* active text area 0 border */
.border-active-0:focus {
  outline: 2px #aaa io !important;
}
.p-1 {
  padding: 0.1rem;
}
.p-toggleswitch.p-toggleswitch-checked .p-toggleswitch-slider {
  background-color: rgb(149 55 248 / var(--tw-bg-opacity)) !important;
}
</style>
