<template>
  <div class="flex-grow">
           <div class="relative w-full h-72 p-6 lg:p-16 gradient-big flex sm:items-center rounded-2xl">
             <div class="text-3xl lg:text-5xl font-bold w-1/2">{{ $t('dashboard.introTitle') }}</div>
             <img class="absolute right-1/2 translate-x-[40%] -bottom-7 w-36 sm:right-[15%] sm:translate-x-0 sm:w-auto sm:-bottom-10" src="../images/intro.svg" alt="">
           </div>

           <div class="my-3 lg:my-7">
      <div class="flex flex-wrap items-center justify-between gap-1">
        <h2 class="py-1 my-px font-bold">{{ $t('dashboard.recommendedTopicsTitle') }}</h2>
        <router-link :to="{ name: 'topics' }" class="text-sm text-primary-main hover:underline">{{ $t('dashboard.viewAll') }}</router-link>
      </div>

 <!-- Loader Section -->
 <div
      class="mt-3 lg:mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-7"
      v-if="loading.loadingRecommendedTopics"
    >
      <div
        v-for="index in 4"
        :key="index"
        class="p-5 bg-primary-medium rounded-xl text-center hover:brightness-110 active:brightness-95"
      >
        <content-loader
          viewBox="0 0 205 160"
          :speed="2"
          primaryColor="#5480f3"
          secondaryColor="#9538f8"
        >
          <rect x="70" y="5" rx="10" ry="10" width="64" height="64" />
          <rect x="12" y="85" rx="3" ry="3" width="180" height="12" />
          <rect x="43" y="110" rx="3" ry="3" width="115" height="12" />
          <rect x="32" y="135" rx="3" ry="3" width="135" height="12" />
        </content-loader>
      </div>
    </div>

    <!-- Topics Section -->

    <transition name="fade" mode="out-in">
      <div
        class="mt-3 lg:mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-7"
        v-if="!loading.loadingRecommendedTopics"
      >
        <div
          v-for="topic in recommendedTopics"
          :key="topic._id"
          class="relative p-5 bg-primary-medium rounded-xl cursor-pointer text-center hover:bg-primary-muted"
          @click="openChatRoom(topic)"
        >
          <div class="relative gradient-bg w-16 h-16 mx-auto rounded-xl flex items-center justify-center">
            <img :src="getIconSrc(topic.icon)" alt="" />
            <div
              class="ml-auto badge-container absolute top-0 right-0 translate-x-2 -translate-y-2"
              v-if="topic.isPremium"
            >
              <span class="badge">PRO</span>
            </div>
          </div>
          <div class="mt-2 mb-1 truncate">{{ topic.label }}</div>
          <div class="text-sm text-primary-muted line-clamp-2">{{ topic.description }}</div>

          <!-- Mobile Question Toggle Icon -->
          <div
            class="mt-2 flex justify-center items-center cursor-pointer"
            @click.stop="toggleQuestions(topic._id)"
          >
            <a class="text-sm text-primary-main ">{{ $t('dashboard.quickQuestions') }}
              <i class="fa-solid fa-arrow-down">
              </i>
            </a>
          </div>

          <!-- Dropdown that shows on icon click (Mobile) or hover (Desktop) -->
          <OnClickOutside @trigger="closeQuestions">
          <transition name="fade">
            <div
              v-if="showQuestions === topic._id"
              class="absolute bg-dark rounded-t-none border border-t-none border-primary-muted left-2 right-2 top-full bg-white shadow-lg rounded-xl p-3 text-left dropdown"
            >
              <ul>
                <li
                  v-for="question in getRandQuestions(topic.questions)"
                  :key="question.order"
                  class="text-sm text-primary-muted hover:bg-primary-medium hover:text-white rounded-xl mb-2 px-3 py-1 z-20"
                  @click="handleQuestionClick(topic, question, $event)"
                >
                  <p>{{ question.question }}</p>
                </li>
              </ul>
            </div>
          </transition>
        </OnClickOutside>
        </div>
      </div>
    </transition>

    </div>
           <div class="my-3 lg:my-7">
             <div class="flex flex-wrap items-center justify-between gap-1">
               <h2 class="py-1 my-px font-bold">{{ $t('dashboard.latestSignalsTitle') }}</h2>
               <router-link :to="{ name: 'signals' }" class="text-sm text-primary-main hover:underline">{{ $t('dashboard.viewAll') }}</router-link>
             </div>

               <div class="mt-3 lg:mt-4 flex flex-col lg:flex-row gap-1 lg:gap-7" v-if="loading.loadingSignals">
                 <div class="p-3 lg:p-5 border border-primary-muted rounded-xl flex flex-wrap" v-for="index in 3" :key="index">
                     <content-loader
                       class="w-full"
                       :speed="2"
                       primaryColor="#5480f3"
                       secondaryColor="#9538f8"
                     >
                       <rect x="20" y="0" rx="6" ry="6" width="150" height="40" />
                       <rect x="250" y="0" rx="6" ry="6" width="150" height="40" />
                       <rect x="20" y="50" rx="6" ry="6" width="120" height="12" />
                       <rect x="22" y="80" rx="6" ry="6" width="330" height="50" />
                     </content-loader>
                   </div>
               </div>
               <transition name="fade" mode="out-in">
             <div v-if="signals.length > 0">
               <div class="mt-3 lg:mt-4 flex flex-col lg:flex-row gap-1 lg:gap-7">
                 <a class="p-3 lg:p-5 border border-primary-muted rounded-xl flex flex-wrap hover:bg-primary-medium" href="" v-for="signal in signals" :key="signal._id">
                   <div class="w-1/2 flex items-center gap-4 flex-shrink-0">
                     <div class="flex-grow">
                       <div class="text-lg font-bold text-white leading-5">{{signal.ticker}}</div>
                       <div class="text-xs text-primary-muted">{{signal.tickerName}}</div>
                     </div>
                   </div>
                   <div class="w-1/2 text-sm text-primary-muted flex-shrink-0 text-right">
                     <div>{{ getFormattedDate(signal.createdAt) }}</div>
                     <div>{{ getFormattedTime(signal.createdAt) }}</div>
                   </div>
                   <div class="w-full">
                     <div class="my-1 line-clamp-2 leading-5">{{ signal.title }}</div>
                   </div>
                   <div class="w-full flex gap-2 flex-shrink-0" v-if="signal.direction==1">
                     <i v-for="i in 5" :key="i" class="icon-bull text-2xl" :class="{'text-green-600': i <= signal.severity, 'text-primary-muted': i > signal.severity}"></i>
                   </div>
                   <div class="w-full flex gap-2 flex-shrink-0" v-if="signal.direction==-1">
                     <i v-for="i in 5" :key="i" class="icon-bear text-2xl" :class="{'text-red-600': i <= signal.severity, 'text-primary-muted': i > signal.severity}"></i>
                     </div>
                 </a>
               </div>
             </div>

             <div v-else-if="signals.length === 0 && !loading.loadingSignals">
               <div class="mt-3 lg:mt-4 p-3 lg:p-7 border border-primary-light rounded-xl text-center">
                 {{ $t('dashboard.noSignalsFound') }}
               </div>
             </div>
           </transition>
           </div>
           <div class="my-3 lg:my-7">
             <div class="grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-7">
               <div>
                 <div class="flex flex-wrap items-center justify-between gap-1">
                   <h2 class="py-1 my-px font-bold">{{ $t('dashboard.topicsTitle') }}</h2>
                   <router-link :to="{ name: 'topics' }" class="text-sm text-primary-main hover:underline">{{ $t('dashboard.allTopics') }}</router-link>
                 </div>
                 <div class="mt-3 lg:mt-4 p-3 lg:p-7 border border-primary-light rounded-xl flex flex-col gap-3 lg:gap-5">
                   <a v-for="topic in topics" :key="topic._id" class="flex gap-4 hover:text-primary-bright cursor-pointer" @click.prevent="openChatRoom(topic)">
                     <div class="w-12 h-12 gradient-bg rounded-xl flex items-center justify-center flex-shrink-0">
                       <img :src="getIconSrc(topic.icon)" alt="" />
                     </div>
                     <div class="flex-grow">
                       <div class="flex gap-4 items-center justify-between">
                         <span>{{topic.label}}</span>
                         <div class="ml-auto badge-container" v-if="topic.isPremium">
                           <span class="badge">PRO</span>
                         </div>
                       </div>
                       <div class="mt-0.5 text-sm text-primary-muted line-clamp-2">{{topic.description}}</div>
                     </div>
                   </a>
                 </div>
               </div>
               <div>
                 <div class="flex flex-wrap items-center justify-between gap-1">
                   <h2 class="py-1 my-px font-bold">{{ $t('dashboard.newsTitle') }}</h2>
                   <router-link :to="{ name: 'news' }" class="text-sm text-primary-main hover:underline">{{ $t('dashboard.allNews') }}</router-link>
                 </div>
                 <div class="mt-3 lg:mt-4 p-3 lg:p-7 border border-primary-light rounded-xl flex flex-col gap-3 lg:gap-7">
                   <div class="flex items-center gap-4" v-for="item in news" :key="item._id">
                     <router-link
                     :to="{ name: 'news-detail', params: { id: item._id } }"
                     class="group relative w-20 h-20 flex-shrink-0 bg-primary-medium rounded-xl overflow-hidden cursor-pointer">
                       <img
                         class="w-full h-full block object-cover transition-all group-hover:scale-105"
                         :src="item.thumbnailUrl"
                         :srcset="`${item.thumbnailUrl} 1x, ${item.thumbnailUrl} 2x`"
                         alt=""
                       >
                     </router-link>
                     <div class="flex-grow">
                       <router-link
                       :to="{ name: 'news-detail', params: { id: item._id } }"
                       class="line-clamp-2 hover:text-primary-bright" href="">{{item.title}}</router-link>
                       <div class="mt-1 text-xs text-primary-muted">{{ getFormattedDate(item.published) }} {{ getFormattedTime(item.published) }} / <a class="text-primary-main underline hover:no-underline" href="">{{item.source}}</a></div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>

</template>

<script>
import { ContentLoader } from 'vue-content-loader';
import { OnClickOutside } from '@vueuse/components';
import * as api from '../api';

export default {
  components: {
    ContentLoader,
    OnClickOutside,
  },
  name: 'DashboardScreen',
  data() {
    return {
      signals: [],
      topics: [],
      recommendedTopics: [],
      news: [],
      showQuestions: null, // Manage which topic's questions are shown
      loading: {
        loadingSignals: true,
        loadingRecommendedTopics: true,
        loadingTopics: true,
        loadingNews: true,
      },
    };
  },
  async created() {
    this.signals = await api.getSignals(1, 3);
    this.loading.loadingSignals = false;

    this.recommendedTopics = await api.getRecommendedTopics();
    this.loading.loadingTopics = false;

    const allTopics = await api.getTopics();
    this.topics = allTopics.sort(() => 0.5 - Math.random()).slice(0, 4);
    this.loading.loadingRecommendedTopics = false;

    const params = {
      page: 1,
      limit: 3,
    };

    this.news = await api.getNews(params);
    this.loading.loadingNews = false;
  },
  methods: {
    closeQuestions() {
      this.showQuestions = null;
    },
    getRandQuestions(questions) {
      return questions.sort(() => 0.5 - Math.random()).slice(0, 3);
    },
    getFormattedDate(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    },
    getFormattedTime(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      return new Date(date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    },
    toggleQuestions(topicId) {
      // Toggle the questions visibility for the selected topic
      if (this.showQuestions === topicId) {
        this.showQuestions = null;
      } else {
        this.showQuestions = topicId;
      }
    },
    getIconSrc(svgString) {
      const base64SVG = btoa(svgString);
      return `data:image/svg+xml;base64,${base64SVG}`;
    },
    openChatRoom(topic) {
      this.$router.push({ path: `/room/${topic._id}`, params: { topic } });
    },
    handleQuestionClick(topic, question, event) {
      event.stopPropagation();
      console.log('Question selected:', question);
      this.$router.push({ path: `/room/${topic._id}`, params: { topic }, query: { question: question.order } });
      // Handle the specific question if necessary
    },
  },
};
</script>

<style scoped>
.badge-container {
 display: flex;
 align-items: center;
}

.badge {
 background-color: #FFC72C;
 color: #8123e4;
 padding: 0.1em 0.6em;
 border-radius: 15px;
 font-weight: bold;
 font-size: 0.75rem; /* Adjust the font size as needed */
}
.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
 opacity: 0;
}

<style scoped>
.badge-container {
 display: flex;
 align-items: center;
}

.badge {
 background-color: #FFC72C;
 color: #8123e4;
 padding: 0.1em 0.6em;
 border-radius: 15px;
 font-weight: bold;
 font-size: 0.75rem; /* Adjust the font size as needed */
}
.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
 opacity: 0;
}

/* Additional Styles
.gradient-bg {
 background: linear-gradient(135deg, #6B73FF 0%, #000DFF 100%);
}

.gradient-big {
 background: linear-gradient(135deg, #6B73FF 0%, #000DFF 100%);
} */

.content-loader {
 background-color: #5480f3;
 border-radius: 0.75rem;
}

.dropdown {
 /* background-color: white;
 border: 1px solid #ccc;
 box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
 border-radius: 8px;
 padding: 0.75rem; */
 z-index: 1000;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-t-none {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

/* border top no color */
.border-t-none {
  border-top: 0px;
}

.rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.left-questions{
  left: 10px;
}
</style>
