import { getAuth, onAuthStateChanged } from 'firebase/auth';

async function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const removelistener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removelistener();
        resolve(user);
      },
      reject,
    );
  });
}

export const ifAuthenticated = async (to, from, next) => {
  if (await getCurrentUser()) {
    next();
    return;
  }

  // Remember full route path in order to redirect to previous url after logging in
  const { fullPath } = to;
  next({
    name: 'login',
    query: { redirect: fullPath !== '/' ? fullPath : undefined },
  });
};

export function ifAdmin(to, from, next) {
  if (getCurrentUser()) {
    next();
  } else {
    next({ name: 'unauthorized' });
  }
}

export function ifUser(to, from, next) {
  if (getCurrentUser()) {
    next();
  } else {
    next({ name: 'unauthorized' });
  }
}

export function ifOperator(to, from, next) {
  if (getCurrentUser()) {
    next();
  } else {
    next({ name: 'unauthorized' });
  }
}

export async function getUserToken(refresh = false) {
  const user = await getCurrentUser();
  if (!user) {
    return null;
  }

  return user.getIdToken(refresh);
  // return new Promise((resolve) => {
  //   const unsub = onAuthStateChanged(getAuth(), async (user) => {
  //     if (user) {
  //       const token = user.accessToken;
  //       resolve(token);
  //     } else {
  //       resolve(null);
  //     }
  //     unsub();
  //   });
  // });
}
