<template>
  <div class="flex-grow" v-if="eventDetail">
    <country-flag :country="eventDetail.country" size="big" />
    <h1 class="py-1 my-px font-bold">{{ eventDetail.title }}</h1>
    <div class="text-sm text-primary-muted">
      {{ getFormattedDate(eventDetail.date) }} {{ getFormattedTime(eventDetail.date) }} / {{ $t('eventDetail.source') }}: <a class="text-primary-main underline hover:no-underline" href="">{{ eventDetail.source }}</a>
    </div>
    <div class="p-3 lg:p-5 mt-4 mb-4 border border-primary-main rounded-xl flex flex-wrap lg:flex-nowrap gap-2 lg:gap-6">
      <div class="w-full lg:w-full flex-shrink-0">
        <div class="grid grid-cols-3 gap-4">
          <div class="signal-value pl-5 flex flex-col text-primary-muted">
            <div class="text-xs">{{ $t('eventDetail.forecast') }}</div>
            <div class="flex items-center">
              <i class="icon-angle-right -ml-1 text-xl"></i>
              <span v-if="eventDetail.forecast" :style="getValueColor(eventDetail.forecast)">{{ eventDetail.forecast }}{{ eventDetail.unit }}</span>
            </div>
          </div>
          <div class="signal-value pl-5 flex flex-col text-primary-muted">
            <div class="text-xs">{{ $t('eventDetail.actual') }}</div>
            <div class="flex items-center">
              <i class="icon-angle-right -ml-1 text-xl"></i>
              <span v-if="eventDetail.actual" :style="getValueColor(eventDetail.actual)">{{ eventDetail.actual }}{{ eventDetail.unit }}</span>
            </div>
          </div>
          <div class="signal-value pl-5 flex flex-col text-primary-muted">
            <div class="text-xs">{{ $t('eventDetail.previous') }}</div>
            <div class="flex items-center">
              <i class="icon-angle-right -ml-1 text-xl"></i>
              <span v-if="eventDetail.previous" :style="getValueColor(eventDetail.previous)">{{ eventDetail.previous }}{{ eventDetail.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap gap-2 text-sm font-semibold">
      <a
        :class="['px-4 py-1.5 border rounded-lg hover:brightness-110 active:brightness-95', selectedIndex === 0 ? 'border-primary-main bg-primary-main' : 'border-primary-light bg-primary-light']"
        href="#"
        @click.prevent="selectedIndex = 0"
      >
        {{ $t('eventDetail.eventTab') }}
      </a>
      <a
        :class="['px-4 py-1.5 border rounded-lg hover:brightness-110 active:brightness-95', selectedIndex === 1 ? 'border-primary-main bg-primary-main' : 'border-primary-light bg-primary-light']"
        href="#"
        @click.prevent="openAnalysis()"
      >
        {{ $t('eventDetail.analysisTab') }}
      </a>
    </div>
    <div class="mt-4 mb-5" v-if="selectedIndex === 0">
      <div class="prose prose-invert prose-sm max-w-none" v-html="eventDetail.comment"></div>
    </div>
    <div class="mt-4 mb-5" v-if="selectedIndex === 1">
      <Loader v-if="isWaitingForAnalysis" />
      <MarkdownRenderer v-if="analysis && !isWaitingForAnalysis" :source="analysis" />
    </div>
    <StartAnalysisModal
      v-if="isModalVisible"
      :remainingAnalisys="remainingAnalisys"
      :startAnalysis="startAnalysis"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as api from '../api';
import MarkdownRenderer from '../components/MarkdownRenderer.vue';
import StartAnalysisModal from '../components/modal/StartAnalysisModal.vue';
import socketManager from '../socketManager';
import Loader from '../components/LoaderComp.vue';

export default {
  name: 'EventDetailScreen',
  components: {
    MarkdownRenderer,
    StartAnalysisModal,
    Loader,
  },
  // take id from route params
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      eventDetail: null,
      selectedIndex: 0,
      remainingAnalisys: 0,
      analysis: null,
      isWaitingForAnalysis: false,
      isLoadingAnalysis: false,
      error: null,
      errorParams: [],
      isModalVisible: false,
    };
  },
  async created() {
    this.eventDetail = await api.getEventDetail(this.id);
    this.remainingAnalisys = await api.getRemainingNewsAnalysis();
    this.analysis = await api.getEventAnalysis(this.id);
    console.log(this.userSubscription);
  },
  computed: {
    ...mapState(['userSubscription']),
  },
  methods: {
    async connectToSocket() {
      console.log('connectToSocket');
      await socketManager.connect();
      socketManager.addEventListener('explain-event', this.handleExplainEvent);
      socketManager.addEventListener('explain-event-finish', this.handleExplainNewsEvent);
    },
    async startAnalysis() {
      this.closeModal();
      this.isWaitingForAnalysis = true;
      await this.connectToSocket();
      this.selectedIndex = 1;
      socketManager.emit('analyze-event', this.id);
    },
    handleExplainEvent(data) {
      console.log('handleExplainevent');
      this.isLoadingAnalysis = true;
      this.isWaitingForAnalysis = false;
      this.analysis = data;
    },
    handleExplainEventFinish(data) {
      console.log('handleExplaineventFinish');
      this.isLoadingAnalysis = false;
    },
    openAnalysis() {
      if (this.analysis) {
        this.selectedIndex = 1;
      } else {
        this.openModal();
      }
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    getFormattedDate(date) {
      console.log(date);
      // date is string 2024-08-05T06:00:00.000Z
      // if date is timestamp, convert it to ISO string
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      // return format like Aug 5, 2024
      return date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    },
    getFormattedTime(date) {
      // if date is timestamp, convert it to ISO string
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      // return format like 03:56 AM
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    },
    getColor(event) {
      // if indicator is string holiday or holidays, return grey
      if (event.indicator === 'holiday' || event.indicator === 'holidays') {
        return 'color: grey';
      }

      // 1: red, 0: orange, -1: yellow
      if (event.importance === 1) {
        return 'color: red';
      }
      if (event.importance === 0) {
        return 'color: orange';
      }
      return 'color: yellow';
    },
    getValueColor(value) {
      // if value is positive return green, if its 0 then grey, else red
      if (value > 0) {
        return 'color: green';
      }
      if (value === 0) {
        return 'color: grey';
      }
      return 'color: red';
    },
  },
};
</script>

<style>
.custom-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 30vh; /* Adjust this to control the maximum height, representing half the viewport height */
}
</style>
