import { createApp } from 'vue';
import Aura from '@primevue/themes/aura';
import Toast from 'vue-toastification';
// import 'floating-vue/dist/style.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import FloatingVue from 'floating-vue';
// import VueModalityV3 from 'vue-modality-v3';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import PrimeVue from 'primevue/config';
import VueSilentbox from 'vue-silentbox';
import VueTelInput from 'vue-tel-input';
import App from './layouts/AppLayout.vue';
import router from './router';
import { i18n } from './i18n';
import firebaseConfig from './config/firebaseConfig';
import 'vue-tel-input/vue-tel-input.css';
import store from './store';

// 3rd party styles
import 'vue-toastification/dist/index.css';
// import 'vue-datepicker-next/index.css';
// import 'bootstrap/dist/css/bootstrap-utilities.min.css';
// import 'bootstrap/dist/css/bootstrap-grid.min.css';
// import 'primevue/resources/themes/saga-blue/theme.css'; // Adjust the theme as needed
// import 'primevue/resources/primevue.min.css';
// import 'vue-silentbox/dist/style.css';

// // Custom styles
import './styles/tailwind.css';
import './styles/icons/style.css';
import CountryFlag from 'vue-country-flag-next';
import mitt from 'mitt';
import Vue3Lottie from 'vue3-lottie';

// import { redirectToRole } from './components/dashboard/dashboard.router';
const emitter = mitt();

// Floating vue config
export const floatingConfig = {
  themes: {
    tooltip: {
      delay: { show: 100 },
    },
    'tooltip-white': {
      $extend: 'tooltip',
    },
  },
};

// Initialize Firebase
initializeApp(firebaseConfig);

// // Set initial user info loading state
store.commit('SET_USER_INFO_LOADING', true);

// // On firebase auth state change
getAuth().onAuthStateChanged(async (user) => {
  if (user) {
    await store.dispatch('USER_INFO_REQUEST');
    store.commit('SET_USER_INFO_LOADING', false);

    // if (router.currentRoute.value.name === 'dashboard') {
    //   redirectToRole(null, null, (route) => router.replace(route));
    // }
  } else {
    store.commit('CLEAR_STATE');
  }
});

const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(PrimeVue, {
    theme: {
      preset: Aura,
    },
  })
  .use(Toast, { hideProgressBar: true })
  .use(FloatingVue, floatingConfig)
  .use(VueSilentbox)
  .use(Vue3Lottie)
  .use(CountryFlag)
  .use(VueTelInput);
  // .component('VueModality', VueModalityV3);
app.component(
  // the registered name
  CountryFlag,
);

app.config.globalProperties.emitter = emitter;

// Mount the app
app.mount('#app');
