<template>
  <div>
    <h2 v-if="mode === 'verifyEmail'">Verify Email</h2>
    <h2 v-if="mode === 'signIn'">Sign In</h2>

    <div class="p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md" v-if="mode === 'resetPassword'">
      <div class="relative p-4 lg:p-7 rounded-xl bg-primary-medium text-center">
        <div class="my-3 lg:my-5">
          <img class="mx-auto" src="../images/logo.svg" alt="">
          <div class="my-4 text-lg font-bold">{{ $t('actions.changePassword') }}</div>
          <div class="text-left">
            <div class="mb-2.5">
              <label class="mb-1 block text-sm text-primary-muted">{{ $t('actions.newPassword') }}</label>
              <div class="relative">
                <input
                  v-model="newPassword"
                  :type="showNewPassword ? 'text' : 'password'"
                  class="form-input w-full h-10 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted"
                  :placeholder="$t('actions.newPasswordPlaceholder')"
                >
                <span
                @click="toggleShowNewPassword"
                class="absolute top-1/2 right-2 -translate-y-1/2 flex bg-transparent border-0 rounded-lg text-primary-muted cursor-pointer hover:brightness-110 active:brightness-95">
                  <i :class="showNewPassword ? 'fa-regular fa-eye-slash text-xl lg:text-2xl' : 'fa-regular fa-eye text-xl lg:text-2xl'"></i>
                </span>
              </div>
              <div class="input-errors" v-for="error of v$.newPassword.$errors" :key="error.$uid">
                <div class="error-msg">{{ $t(error.$message) }}</div>
              </div>
            </div>
            <div class="mb-2.5">
              <label class="mb-1 block text-sm text-primary-muted">{{ $t('actions.confirmPassword') }}</label>
              <div class="relative">
                <input
                  v-model="confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted"
                  :placeholder="$t('actions.confirmPasswordPlaceholder')"
                />
                <span
                @click="toggleShowConfirmPassword"
                class="absolute top-1/2 right-2 -translate-y-1/2 flex bg-transparent border-0 rounded-lg text-primary-muted cursor-pointer hover:brightness-110 active:brightness-95">
                  <i :class="showConfirmPassword ? 'fa-regular fa-eye-slash text-xl lg:text-2xl' : 'fa-regular fa-eye text-xl lg:text-2xl'"></i>
                </span>
              </div>
              <div class="input-errors" v-for="error of v$.confirmPassword.$errors" :key="error.$uid">
                <div class="error-msg">{{ $t(error.$message) }}</div>
              </div>
            </div>
          </div>
          <div class="w-full mt-4 flex flex-col gap-4 items-center font-semibold">
            <button
              class="w-full px-4 py-1.5 bg-primary-main rounded-lg hover:brightness-110 active:brightness-95"
              @click="onSubmit()"
            >
              {{ $t('actions.changePassword') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <p v-if="message">{{ message }}</p>
    <p v-if="error">{{ error }}</p>
  </div>
</template>
<script>
import {
  getAuth, confirmPasswordReset, applyActionCode, signInWithEmailLink,
} from 'firebase/auth';
import { useVuelidate } from '@vuelidate/core';
import {
  required, minLength, helpers,
} from '@vuelidate/validators';
import { useToast } from 'vue-toastification';

export default {
  name: 'ActionsScreen',
  setup() {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    };
  },
  data() {
    return {
      mode: '',
      oobCode: '',
      apiKey: '',
      lang: '',
      newPassword: '',
      confirmPassword: '',
      message: '',
      error: '',
      showNewPassword: false, // For toggling new password visibility
      showConfirmPassword: false, // For toggling confirm password visibility
    };
  },
  created() {
    this.mode = this.$route.query.mode;
    this.oobCode = this.$route.query.oobCode;
    this.apiKey = this.$route.query.apiKey;
    this.lang = this.$route.query.lang;

    switch (this.mode) {
      case 'resetPassword':
        // Handle reset password flow
        break;
      case 'verifyEmail':
        this.verifyEmail();
        break;
      case 'signIn':
        this.signInWithEmailLink();
        break;
      default:
        this.error = 'Invalid action mode.';
    }
  },
  validations() {
    return {
      newPassword: {
        required: helpers.withMessage('errors.newPasswordRequired', required),
        minLength: helpers.withMessage('errors.passwordMinLength', minLength(8)),
      },
      confirmPassword: {
        required: helpers.withMessage('errors.confirmPasswordRequired', required),
        sameAsNew: helpers.withMessage('errors.passwordsDoNotMatch', (value) => value === this.newPassword),
      },
    };
  },
  methods: {
    async onSubmit() {
      console.log('Login');

      this.v$.$validate();
      if (this.v$.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        await this.resetPassword();
      }
    },
    toggleShowNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleShowConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async resetPassword() {
      const auth = getAuth();
      try {
        await confirmPasswordReset(auth, this.oobCode, this.newPassword);
        this.toast.success('Password reset successfully!');
        this.error = '';
      } catch (err) {
        this.toast.error(err.message);
        this.error = err.message;
        this.message = '';
      }
      // redirect to login screen
      this.$router.push({ name: 'login' });
    },
    async verifyEmail() {
      const auth = getAuth();
      try {
        await applyActionCode(auth, this.oobCode);
        this.message = 'Email verified successfully!';
        this.error = '';
      } catch (err) {
        this.error = err.message;
        this.message = '';
      }
    },
    async signInWithEmailLink() {
      const auth = getAuth();
      try {
        const email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          this.error = 'No email found for sign-in. Please try again.';
          return;
        }
        await signInWithEmailLink(auth, email, window.location.href);
        window.localStorage.removeItem('emailForSignIn');
        this.message = 'Signed in successfully!';
        this.error = '';
      } catch (err) {
        this.error = err.message;
        this.message = '';
      }
    },
  },
};
</script>
