<template>
  <div class="flex-grow">
    <div class="my-3 lg:my-7" v-if="loading">
    <Loader/>
    </div>
    <transition name="fade" mode="out-in">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-7" v-show="!loading">
        <a
          v-for="topic in topics"
          :key="topic._id"
          class="p-5 bg-primary-medium rounded-xl text-center cursor-pointer hover:brightness-110 active:brightness-95"
          @click.prevent="openChatRoom(topic)"
        >
        <div v-if="topic._id == 'berry'">

          <!-- <router-link
              :to="{
                name: 'room',
                params: { id: guru._id },
                query: { type: 'guru' }
              }"
              class="group flex items-center gap-3 active:brightness-95"
              active-class="active-link"
              exact-active-class="exact-active-link"
              @click.native="closeNav"
            >
              <span
                :class="[
                  'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                  $route.query.type === 'guru' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
                ]"
              >
                <div class="w-10 lg:w-12 flex-shrink-0">
                  <div class="relative w-10 h-10 lg:w-12 lg:h-12 rounded-xl overflow-hidden flex items-center justify-center">
                    <img src="../images/bull-front-avatar.svg" alt="">
                  </div>
                </div>
              </span>
              <div>
                <span>{{ $t('sidebar.guruName') }}</span>
                <div class="text-sm text-primary-muted line-clamp-2">{{ $t('sidebar.guruTitle') }}</div>
              </div>
            </router-link> -->

          <div class="relative gradient-bg w-16 h-16 mx-auto rounded-xl flex items-center justify-center">
            <img src="../images/bull-front-avatar.svg" alt="">

          </div>
          <div class="mt-2 mb-1 truncate">{{ $t('sidebar.guruName') }}</div>
          <div class="text-sm text-primary-muted line-clamp-2">{{$t('sidebar.guruTitle') }}</div>
        </div>
        <div v-else>
          <div class="relative gradient-bg w-16 h-16 mx-auto rounded-xl flex items-center justify-center">
            <img :src="getIconSrc(topic.icon)" alt="" />
            <div
              class="ml-auto badge-container absolute top-0 right-0 translate-x-2 -translate-y-2"
              v-if="topic.isPremium"
            >
              <span class="badge">PRO</span>
            </div>
          </div>
          <div class="mt-2 mb-1 truncate">{{ topic.label }}</div>
          <div class="text-sm text-primary-muted line-clamp-2">{{ topic.description }}</div>
        </div>
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import * as api from '../api';
import Loader from '../components/LoaderComp.vue';

export default {
  name: 'TopicsScreen',
  computed: {
    searchQuery() {
      return this.$route.query.search || '';
    },
  },
  components: {
    Loader,
  },
  watch: {
    '$route.query.search': {
      handler(newValue) {
        // Handle the search value change
        console.log('Search value changed:', newValue);
        // You can call a method to fetch the topics based on the search value
        this.fetchTopics(newValue);
      },
      immediate: true,
    },
  },
  data() {
    return {
      topics: [],
      loading: true,
    };
  },
  async created() {
    this.topics = await api.getTopics(this.searchQuery);
    this.loading = false;
  },
  methods: {
    async fetchTopics(search) {
      this.topics = await api.getTopics(search);
    },
    getIconSrc(svgString) {
      const base64SVG = btoa(svgString);
      return `data:image/svg+xml;base64,${base64SVG}`;
    },
    openChatRoom(topic) {
      if (topic._id === 'berry') {
        this.$router.push({ name: 'room', params: { id: 'berry' }, query: { type: 'guru' } });
      } else {
        this.$router.push({ path: `/room/${topic._id}`, params: { topic, type: 'guru' } });
      }
    },
  },
};
</script>

<style scoped>
.badge-container {
  display: flex;
  align-items: center;
}

.badge {
  background-color: #FFC72C;
  color: #8123e4;
  padding: 0.1em 0.6em;
  border-radius: 15px;
  font-weight: bold;
  font-size: 0.75rem; /* Adjust the font size as needed */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
