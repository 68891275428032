import { createWebHistory, createRouter } from 'vue-router';
import axios from 'axios';
import MainLayout from './layouts/MainLayout.vue';
import DashboardScreen from './views/DashboardScreen.vue';
import TopicsScreen from './views/TopicsScreen.vue';
import ProfileScreen from './views/ProfileScreen.vue';
import NewsScreen from './views/NewsScreen.vue';
import ShopScreen from './views/ShopScreen.vue';
import LoginScreen from './views/LoginScreen.vue';
import SignalsScreen from './views/SignalsScreen.vue';
import CalendarScreen from './views/CalendarScreen.vue';
import EventDetailScreen from './views/EventDetailScreen.vue';
import NewsDetailScreen from './views/NewsDetailScreen.vue';
import RegisterScreen from './views/RegisterScreen.vue';
import SignalDetailScreen from './views/SignalDetailScreen.vue';
import TopicScreen from './views/TopicScreen.vue';
import ForgotPasswordScreen from './views/ForgotPasswordScreen.vue';
import ActionsScreen from './views/ActionsScreen.vue';
import * as auth from './auth';
import store, { CLEAR_STATE } from './store';
import * as api from './api';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      component: MainLayout,
      beforeEnter: auth.ifAuthenticated,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: DashboardScreen,
          meta: {
            title: 'Dashboard | Thaurus Guru',
            description: 'Dashboard for Thaurus Guru',
          },
        },
        {
          path: 'topics',
          name: 'topics',
          component: TopicsScreen,
          meta: {
            title: 'Topics | Thaurus Guru',
            description: 'Topics for Thaurus Guru',
          },
        },
        {
          path: 'room/:id',
          name: 'room',
          component: TopicScreen,
          meta: {
            title: 'Room | Thaurus Guru',
            description: 'Room screen',
          },
          props: (route) => ({ id: route.params.id, type: route.query.type, question: Number(route.query.question) || null }),
        },
        {
          path: 'profile',
          name: 'profile',
          component: ProfileScreen,
          meta: {
            title: 'Profile | Thaurus Guru',
            description: 'User profile screen',
          },
        },
        {
          path: 'news',
          name: 'news',
          component: NewsScreen,
          meta: {
            title: 'News | Thaurus Guru',
            description: 'News screen',
          },
        },
        {
          path: 'news/:id',
          name: 'news-detail',
          component: NewsDetailScreen,
          meta: {
            title: 'News Detail | Thaurus Guru',
            description: 'News detail screen',
          },
          props: true,
        },
        {
          path: 'shop',
          name: 'shop',
          component: ShopScreen,
          meta: {
            title: 'Shop | Thaurus Guru',
            description: 'Shop screen',
          },
        },
        {
          path: 'signals',
          name: 'signals',
          component: SignalsScreen,
          meta: {
            title: 'Signals | Thaurus Guru',
            description: 'Signals screen',
          },
        },
        {
          path: 'signal/:id',
          name: 'signal-detail',
          component: SignalDetailScreen,
          meta: {
            title: 'Signal Detail | Thaurus Guru',
            description: 'Signal detail screen',
          },
          props: true,
        },
        {
          path: 'calendar',
          name: 'calendar',
          component: CalendarScreen,
          meta: {
            title: 'Calendar | Thaurus Guru',
            description: 'Calendar screen',
          },
        },
        {
          path: 'event/:id',
          name: 'event-detail',
          component: EventDetailScreen,
          meta: {
            title: 'Event Detail | Thaurus Guru',
            description: 'Event detail screen',
          },
          props: true,
        },
      ],
    },
    {
      path: '/actions',
      name: 'actions',
      component: ActionsScreen,
      meta: {
        title: 'Actions | Thaurus Guru',
        description: 'Actions screen',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: LoginScreen,
      meta: {
        title: 'Login | Thaurus Guru',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterScreen,
      meta: {
        title: 'Register | Thaurus Guru',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPasswordScreen,
      meta: {
        title: 'Forgot Password | Thaurus Guru',
      },
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      // component: Unauthorized,
      // Uncomment and import Unauthorized component if available
    },
    {
      path: '/not-found',
      name: 'not-found',
      // component: NotFound,
      // Uncomment and import NotFound component if available
    },
    {
      path: '/blocked',
      name: 'blocked',
      // component: Blocked,
      // Uncomment and import Blocked component if available
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'not-found' },
    },
  ],
});

api.axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error && error.response) {
      const { status } = error.response;
      const { fullPath, name } = router.currentRoute.value;

      if (status === 401 && !['login'].includes(name)) {
        await router.replace({
          name: 'login',
          query: {
            redirect:
              fullPath !== '/' && name !== 'login' ? fullPath : undefined,
            token: undefined,
          },
        });
        store.commit(CLEAR_STATE);
      } else if (status === 403) {
        await router.replace({ name: 'login' });
      }
    }

    throw error;
  },
);

api.axiosInstance.interceptors.request.use(async (request) => {
  const token = await auth.getUserToken(true);

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

router.beforeEach((to, from, next) => {
  if (to.meta?.title) {
    document.title = to.meta.title;
  }

  if (to.meta?.description) {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', to.meta.description);
    }
  }

  next();
});

export default router;
