<template>
  <div>
    <div class="flex items-center">
      <div
        v-for="(file, index) in files"
        :key="index"
        class="p-3 flex items-center gap-2"
      >
        <span class="text-sm">{{ file.name }}</span>
        <button
          @click="removeFile(index)"
          class="text-red-500 hover:text-red-700"
        >
          x
        </button>
      </div>
      <div
        v-for="(file, index) in images"
        :key="index"
        class="p-3 flex items-center gap-2"
      >
        <img :src="file.url" class="w-20 h-20 object-cover rounded-lg" />
        <button
          @click="removeImage(index)"
          class="text-red-500 hover:text-red-700"
        >
          x
        </button>
      </div>
    </div>
    <div class="p-4 flex items-center gap-4">
      <label
        class="w-10 h-10 flex flex-shrink-0 items-center justify-center bg-primary-medium border-0 rounded-lg text-primary-main cursor-pointer hover:bg-primary-main hover:text-white active:brightness-95"
      >
        <input type="file" class="hidden" @change="handleFileSelect" multiple />
        <i class="icon-add text-2xl"></i>
      </label>
      <textarea
        v-model="message"
        ref="textarea"
        class="form-textarea w-full px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted resize-none"
        placeholder="Type a message or paste an image"
        rows="1"
        @input="autoResize"
        @keydown="handleKeyDown"
        @paste.prevent="handlePaste"
      ></textarea>
      <button
        :disabled="!message.trim()"
        :class="[
          'w-10 h-10 flex flex-shrink-0 items-center justify-center border-0 rounded-lg cursor-pointer',
          message.trim()
            ? 'bg-primary-main text-white hover:brightness-110 active:brightness-95'
            : 'bg-neutral-300 text-gray-500 cursor-not-allowed',
        ]"
        @click="handleSendMessage"
      >
        <i class="icon-send text-2xl"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  props: {
    sendMessage: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    const message = ref('');
    const images = ref([]);
    const files = ref([]);
    const textarea = ref(null);

    const maxRows = 6;
    const lineHeight = 20; // Assuming line height is 20px (adjust based on your CSS)

    const autoResize = () => {
      if (textarea.value) {
        textarea.value.style.height = 'auto'; // Reset the height to auto
        const scrollHeight = textarea.value.scrollHeight;
        const rows = Math.floor(scrollHeight / lineHeight);
        const maxHeight = lineHeight * maxRows;

        if (scrollHeight > maxHeight) {
          textarea.value.style.overflowY = 'auto';
          textarea.value.style.height = `${maxHeight}px`;
        } else {
          textarea.value.style.overflowY = 'hidden';
          textarea.value.style.height = `${scrollHeight}px`;
        }
      }
    };

    onMounted(() => {
      autoResize(); // Initial call to set height properly
    });

    const handleSendMessage = () => {
      const trimmedMessage = message.value.trim();
      if (trimmedMessage || images.value.length || files.value.length) {
        emit('sendMessage', trimmedMessage, images.value, files.value);
        resetInputs();
      }
    };

    const handleFileSelect = (event) => {
      const selectedFiles = Array.from(event.target.files);
      selectedFiles.forEach(processFile);
      event.target.value = ''; // Reset file input
    };

    const handlePaste = (event) => {
      const clipboardData = event.clipboardData;
      const items = clipboardData.items;

      for (const item of items) {
        if (item.type.startsWith('image/')) {
          const file = item.getAsFile();
          processFile(file);
          event.preventDefault(); // Prevent pasting the image as text
        } else if (item.type === 'text/plain') {
          const text = clipboardData.getData('Text');
          message.value += text;
        }
      }
    };

    const processFile = (file) => {
      const fileType = file.type.split('/')[0];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      const isImage = ['png', 'jpeg', 'jpg'].includes(fileExtension);
      const isFile = ['pdf', 'docx', 'xls'].includes(fileExtension);

      if (fileType === 'image' && isImage) {
        addImage(file);
      } else if (fileType === 'application' && isFile) {
        files.value.push(file);
      } else {
        alert(
          'Invalid file type. Only PNG, JPEG, JPG, XLS, DOCX, and PDF files are allowed.'
        );
      }
    };

    const addImage = (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        images.value.push({ name: file.name, url: e.target.result });
      };
      reader.readAsDataURL(file);
    };

    const removeImage = (index) => {
      images.value.splice(index, 1);
    };

    const removeFile = (index) => {
      files.value.splice(index, 1);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevent adding a new line
        handleSendMessage();
      }
    };

    const resetInputs = () => {
      message.value = '';
      images.value = [];
      files.value = [];
      if (textarea.value) {
        textarea.value.style.height = 'auto'; // Reset to the initial height
      }
    };

    return {
      message,
      images,
      files,
      textarea,
      handleSendMessage,
      handleFileSelect,
      handlePaste,
      removeImage,
      removeFile,
      handleKeyDown,
      autoResize,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>
