<template>
  <footer class="bg-primary-medium py-5 lg:py-8 text-center lg:text-left">
    <div class="w-full max-w-main mx-auto px-3">
      <div class="flex flex-col lg:flex-row lg:justify-between gap-5">
        <div>
          <a class="inline-flex" href=""><img class="block" src="../images/logo.svg" alt=""></a>
          <p class="mt-3 lg:mt-5 text-primary-muted">{{ $t('footer.description') }}</p>
        </div>
        <div>
          <h3 class="mb-3 lg:mb-5 font-semibold">{{ $t('footer.contactUs') }}</h3>
          <ul class="space-y-3">
            <li>
              <a class="group flex items-center justify-center lg:justify-start" href="tel:+431234567810"><i class="icon-phone text-xl mr-2 text-primary-muted group-hover:text-white"></i><span>{{ $t('footer.phone') }}</span></a>
            </li>
            <li>
              <a class="group flex items-center justify-center lg:justify-start" href="mailto:support@thaurusguru.com"><i class="icon-email text-xl mr-2 text-primary-muted group-hover:text-white"></i><span>{{ $t('footer.email') }}</span></a>
            </li>
            <li>
              <a class="group flex items-center justify-center lg:justify-start" href="https://thaurusguru.com"><i class="icon-global text-xl mr-2 text-primary-muted group-hover:text-white"></i><span>{{ $t('footer.website') }}</span></a>
            </li>
          </ul>
        </div>
        <div>
          <h3 class="mb-3 lg:mb-5 font-semibold">{{ $t('footer.registeredAddress') }}</h3>
          <div>{{ $t('footer.address') }}</div>
        </div>
        <div>
          <h3 class="mb-3 lg:mb-5 font-semibold">{{ $t('footer.weAccept') }}</h3>
          <div class="flex gap-2.5 justify-center lg:justify-start">
            <img src="../images/visa.svg" alt="VISA">
            <img src="../images/mastercard.svg" alt="MasterCard">
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
