<template>
  <div class="fixed top-0 left-0 w-full h-full p-3 overlay z-50">
    <div class="p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md">
      <div class="relative p-4 lg:p-7 rounded-xl bg-primary-medium text-center">
        <button class="absolute top-2.5 right-2.5 bg-transparent border-0 flex cursor-pointer" @click="closeModal">
          <i class="icon-close text-3xl text-primary-muted hover:brightness-110 active:brightness-95"></i>
        </button>
        <div class="my-3 lg:my-5">
          <div class="text-lg font-bold">
            {{ title }}
          </div>
          <div class="text-sm text-primary-main">
            {{ message }}
          </div>
          <div class="w-60 mt-4 mx-auto flex gap-4 items-center font-semibold">
            <button class="w-full px-4 py-1.5 border border-primary-main bg-primary-main rounded-lg hover:brightness-110 active:brightness-95" @click="closeModal">
              {{ cancelText }}
            </button>
            <button class="w-full px-4 py-1.5 border border-danger-main bg-danger-main rounded-lg hover:brightness-110 active:brightness-95" @click="confirmAction">
              {{ confirmText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Confirm Action',
    },
    message: {
      type: String,
      required: true,
      default: 'Are you sure you want to proceed?',
    },
    confirmText: {
      type: String,
      required: true,
      default: 'Confirm',
    },
    cancelText: {
      type: String,
      required: true,
      default: 'Cancel',
    },
    confirmAction: {
      type: Function,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.border-danger-main {
  border-color: #FF4D4F;
}
.bg-danger-main {
  background-color: #FF4D4F;
}
</style>
