<template>
    <router-link class="p-3 lg:p-5 border border-primary-main rounded-xl flex flex-wrap lg:flex-nowrap lg:items-center lg:gap-2 hover:bg-primary-medium"
    :to="{ name: 'signal-detail', params: { id: signal._id } }">
                    <div class="w-1/2 lg:w-1/5 flex items-center gap-4 flex-shrink-0">
                      <!-- <img class="w-[38px]" src="../images/coins/btc.svg" alt=""> -->
                      <div class="flex-grow">
                        <div class="text-lg font-bold text-white leading-5">{{ signal.ticker }}</div>
                        <div class="text-xs text-primary-muted">{{ signal.tickerName }}</div>
                      </div>
                    </div>
                    <div class="w-1/2 lg:w-1/5 text-sm text-primary-muted flex-shrink-0 text-right lg:text-left">
                      <div>{{ getFormattedDate(signal.createdAt) }}</div>
                      <div>{{ getFormattedTime(signal.createdAt) }}</div>
                    </div>
                    <div class="w-full">
                      <div class="my-1 lg:my-0 line-clamp-2 leading-5">{{signal.title}}</div>
                    </div>

                    <div class="w-full lg:w-auto flex gap-2 flex-shrink-0" v-if="signal.direction==1">

                    <i v-for="i in 5" :key="i" class="icon-bull text-2xl" :class="{'text-green-600': i <= signal.severity, 'text-primary-muted': i > signal.severity}"></i>

                  </div>
                  <div class="w-full lg:w-auto flex gap-2 flex-shrink-0" v-if="signal.direction==-1">
                    <i v-for="i in 5" :key="i" class="icon-bear text-2xl" :class="{'text-red-600': i <= signal.severity, 'text-primary-muted': i > signal.severity}"></i>
                    </div>
                  </router-link>
                  <!-- <a class="p-3 lg:p-5 border border-green-600 rounded-xl flex flex-wrap lg:flex-nowrap lg:items-center lg:gap-2 hover:bg-primary-medium" href="">
                    <div class="w-1/2 lg:w-1/5 flex items-center gap-4 flex-shrink-0">
                      <img class="w-[38px]" src="../images/coins/usdt.svg" alt="">
                      <div class="flex-grow">
                        <div class="text-lg font-bold text-white leading-5">USDT</div>
                        <div class="text-xs text-primary-muted">Tether</div>
                      </div>
                    </div>
                    <div class="w-1/2 lg:w-1/5 text-sm text-primary-muted flex-shrink-0 text-right lg:text-left">
                      <div>Apr 28, 2024</div>
                      <div>03:56 AM</div>
                    </div>
                    <div class="w-full">
                      <div class="my-1 lg:my-0 line-clamp-2 leading-5">Some description of the signal should be placed here</div>
                    </div>
                    <div class="w-full lg:w-auto flex gap-2 flex-shrink-0">
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-primary-muted"></i>
                    </div>
                  </a>
                  <a class="p-3 lg:p-5 border border-primary-muted rounded-xl flex flex-wrap lg:flex-nowrap lg:items-center lg:gap-2 hover:bg-primary-medium" href="">
                    <div class="w-1/2 lg:w-1/5 flex items-center gap-4 flex-shrink-0">
                      <img class="w-[38px]" src="../images/coins/btc.svg" alt="">
                      <div class="flex-grow">
                        <div class="text-lg font-bold text-white leading-5">BTC</div>
                        <div class="text-xs text-primary-muted">Bitcoin</div>
                      </div>
                    </div>
                    <div class="w-1/2 lg:w-1/5 text-sm text-primary-muted flex-shrink-0 text-right lg:text-left">
                      <div>Apr 28, 2024</div>
                      <div>03:56 AM</div>
                    </div>
                    <div class="w-full">
                      <div class="my-1 lg:my-0 line-clamp-2 leading-5">Some description of the signal should be placed here</div>
                    </div>
                    <div class="w-full lg:w-auto flex gap-2 flex-shrink-0">
                      <i class="icon-bear text-2xl text-red-600"></i>
                      <i class="icon-bear text-2xl text-red-600"></i>
                      <i class="icon-bear text-2xl text-red-600"></i>
                      <i class="icon-bear text-2xl text-red-600"></i>
                      <i class="icon-bear text-2xl text-primary-muted"></i>
                    </div>
                  </a>
                  <a class="p-3 lg:p-5 border border-primary-muted rounded-xl flex flex-wrap lg:flex-nowrap lg:items-center lg:gap-2 hover:bg-primary-medium" href="">
                    <div class="w-1/2 lg:w-1/5 flex items-center gap-4 flex-shrink-0">
                      <img class="w-[38px]" src="../images/coins/usdt.svg" alt="">
                      <div class="flex-grow">
                        <div class="text-lg font-bold text-white leading-5">USDT</div>
                        <div class="text-xs text-primary-muted">Tether</div>
                      </div>
                    </div>
                    <div class="w-1/2 lg:w-1/5 text-sm text-primary-muted flex-shrink-0 text-right lg:text-left">
                      <div>Apr 28, 2024</div>
                      <div>03:56 AM</div>
                    </div>
                    <div class="w-full">
                      <div class="my-1 lg:my-0 line-clamp-2 leading-5">Some description of the signal should be placed here</div>
                    </div>
                    <div class="w-full lg:w-auto flex gap-2 flex-shrink-0">
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-primary-muted"></i>
                    </div>
                  </a>
                  <a class="p-3 lg:p-5 border border-primary-muted rounded-xl flex flex-wrap lg:flex-nowrap lg:items-center lg:gap-2 hover:bg-primary-medium" href="">
                    <div class="w-1/2 lg:w-1/5 flex items-center gap-4 flex-shrink-0">
                      <img class="w-[38px]" src="../images/coins/btc.svg" alt="">
                      <div class="flex-grow">
                        <div class="text-lg font-bold text-white leading-5">BTC</div>
                        <div class="text-xs text-primary-muted">Bitcoin</div>
                      </div>
                    </div>
                    <div class="w-1/2 lg:w-1/5 text-sm text-primary-muted flex-shrink-0 text-right lg:text-left">
                      <div>Apr 28, 2024</div>
                      <div>03:56 AM</div>
                    </div>
                    <div class="w-full">
                      <div class="my-1 lg:my-0 line-clamp-2 leading-5">Some description of the signal should be placed here</div>
                    </div>
                    <div class="w-full lg:w-auto flex gap-2 flex-shrink-0">
                      <i class="icon-bear text-2xl text-red-600"></i>
                      <i class="icon-bear text-2xl text-red-600"></i>
                      <i class="icon-bear text-2xl text-red-600"></i>
                      <i class="icon-bear text-2xl text-red-600"></i>
                      <i class="icon-bear text-2xl text-primary-muted"></i>
                    </div>
                  </a>
                  <a class="p-3 lg:p-5 border border-primary-muted rounded-xl flex flex-wrap lg:flex-nowrap lg:items-center lg:gap-2 hover:bg-primary-medium" href="">
                    <div class="w-1/2 lg:w-1/5 flex items-center gap-4 flex-shrink-0">
                      <img class="w-[38px]" src="../images/coins/usdt.svg" alt="">
                      <div class="flex-grow">
                        <div class="text-lg font-bold text-white leading-5">USDT</div>
                        <div class="text-xs text-primary-muted">Tether</div>
                      </div>
                    </div>
                    <div class="w-1/2 lg:w-1/5 text-sm text-primary-muted flex-shrink-0 text-right lg:text-left">
                      <div>Apr 28, 2024</div>
                      <div>03:56 AM</div>
                    </div>
                    <div class="w-full">
                      <div class="my-1 lg:my-0 line-clamp-2 leading-5">Some description of the signal should be placed here</div>
                    </div>
                    <div class="w-full lg:w-auto flex gap-2 flex-shrink-0">
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-green-600"></i>
                      <i class="icon-bull text-2xl text-primary-muted"></i>
                    </div>
                  </a> -->
</template>
<script>
export default {
  name: 'SignalComp',
  props: {
    signal: Object,
  },
  created() {
    console.log(this.signal);
  },
  methods: {
    getColor(signal) {
      return signal === 'bull' ? 'text-green-600' : 'text-red-600';
    },
    getFormattedDate(date) {
      console.log(date);
      // date is string 2024-08-05T06:00:00.000Z
      // if date is timestamp, convert it to ISO string
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      // return format like Aug 5, 2024
      return date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    },
    getFormattedTime(date) {
      // if date is timestamp, convert it to ISO string
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      // return format like 03:56 AM
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    },
  },
};
</script>
