/* eslint-disable no-shadow */
import socketManager from '../../socketManager';
import * as api from '../../api';
import * as Constants from '../../constants';

const state = {
  messages: [],
  isLoadingMessages: false,
  enableSend: false,
  isWriting: false,
  isRecording: false,
  showScrollButton: false,
  needToScroll: false,
  showDisclaimer: false,
  requireAgreement: false,
  isTopic: false,
  error: null,
  errorParams: [],
  room: null,
};

const getters = {
  getRoom: (state) => state.room,
  getMessages: (state) => state.messages,
  isLoadingMessages: (state) => state.isLoadingMessages,
  enableSend: (state) => state.enableSend,
  isWriting: (state) => state.isWriting,
  isRecording: (state) => state.isRecording,
  showScrollButton: (state) => state.showScrollButton,
  needToScroll: (state) => state.needToScroll,
  showDisclaimer: (state) => state.showDisclaimer,
  requireAgreement: (state) => state.requireAgreement,
  isTopic: (state) => state.isTopic,
  error: (state) => state.error,
  errorParams: (state) => state.errorParams,
};

const mutations = {
  setRoom(state, room) {
    state.room = room;
  },
  setMessages(state, messages) {
    state.messages = messages;
  },
  addMessage(state, message) {
    state.messages.push(message);
  },
  setLoadingMessages(state, isLoading) {
    state.isLoadingMessages = isLoading;
  },
  setEnableSend(state, enableSend) {
    state.enableSend = enableSend;
  },
  setIsWriting(state, isWriting) {
    state.isWriting = isWriting;
  },
  setIsRecording(state, isRecording) {
    state.isRecording = isRecording;
  },
  setShowScrollButton(state, showScrollButton) {
    state.showScrollButton = showScrollButton;
  },
  setNeedToScroll(state, needToScroll) {
    state.needToScroll = needToScroll;
  },
  setShowDisclaimer(state, showDisclaimer) {
    state.showDisclaimer = showDisclaimer;
  },
  setRequireAgreement(state, requireAgreement) {
    state.requireAgreement = requireAgreement;
  },
  setIsTopic(state, isTopic) {
    state.isTopic = isTopic;
  },
  setError(state, error) {
    state.error = error;
  },
  setErrorParams(state, errorParams) {
    state.errorParams = errorParams;
  },
  updateMessageImages(state, { id, images }) {
    const message = state.messages.find((msg) => msg._id === id);
    if (message) {
      message.images = images;
    }
  },
  updateMessageFiles(state, { id, files }) {
    const message = state.messages.find((msg) => msg._id === id);
    if (message) {
      message.files = files;
    }
  },
};

const actions = {
  async connectToSocket({ commit, dispatch }, room) {
    // set room
    commit('setRoom', room);
    console.log('connectToSocket');
    console.log(room._id);

    commit('setLoadingMessages', true);

    await socketManager.connect();
    socketManager.addEventListener('init-chat', (data) => dispatch('handleInitChat', data));
    socketManager.addEventListener('confirm-message', (data) => dispatch('confirmMessage', data));
    socketManager.addEventListener('new-message', (data) => dispatch('handleNewMessage', data));
    socketManager.addEventListener('write-message', (data) => dispatch('handleWriteMessage', data));
    socketManager.addEventListener('finish-message', (data) => dispatch('handleFinishMessage', data));
    socketManager.addEventListener('run-action', (data) => dispatch('handleActionButton', data));
    socketManager.addEventListener('function-call-start', (data) => dispatch('handleFunctionCallStart', data));
    socketManager.addEventListener('function-call-finish', (data) => dispatch('handleFunctionCallFinish', data));
    socketManager.addEventListener('error', (data) => dispatch('handleFunctionCallError', data));

    socketManager.emit('join-room', room._id);

    // commit('setLoadingMessages', false);
  },
  handleInitChat({ commit }, data) {
    console.log('handleInitChat');
    const messages = data.map((item) => item);
    commit('setMessages', messages);
  },
  handleNewMessage({ commit }, data) {
    console.log('handleNewMessage');
    const message = data;
    commit('addMessage', message);
    commit('setNeedToScroll', true);
  },
  handleWriteMessage({ commit, state }, data) {
    console.log('handleWriteMessage');
    commit('setIsWriting', true);
    const lastMessage = state.messages.find((msg) => msg._id === data._id);
    if (lastMessage) {
      lastMessage.messages = data.messages;
      lastMessage.images = data.images;
    }
  },
  handleFinishMessage({ commit }) {
    console.log('handleFinishMessage');
    commit('setIsWriting', false);
  },
  handleActionButton({ commit }, data) {
    console.log(`handleActionButton data: ${data}`);
    commit('setAction', data);
  },
  confirmMessage({ commit, state }, data) {
    console.log(`confirmMessage data: ${data}`);
    // const message = state.messages.find((msg) => msg._id === data._id);
    // if (message) {
    //   message.status = Constants.sentStatus;
    //   message.imagesStatus = Constants.uploadedStatus;
    //   message.fileStatus = Constants.uploadedStatus;
    //   message.file = ChatUtils.createFileModel(data.file);
    //   message.images = ChatUtils.createImagesList(data.images);
    // }
  },
  handleFunctionCallStart({ commit, state }, data) {
    console.log(`handleFunctionCallStart data: ${data}`);
    // const lastMessage = state.messages[state.messages.length - 1];
    // if (lastMessage) lastMessage.function = data;
  },
  handleFunctionCallFinish({ commit, state }, data) {
    console.log(`handleFunctionCallFinish data: ${data}`);
    // const lastMessage = state.messages[state.messages.length - 1];
    // if (lastMessage) lastMessage.function = null;
  },
  handleFunctionCallError({ commit }, data) {
    console.log(`handleFunctionCallError data: ${data}`);
    const params = data.params ? data.params.map((param) => param) : [];
    commit('setErrorParams', params);
    commit('setError', data.code || 'Unknown error');
  },
  async sendMessage({ commit, state }, data) {
    // We need generate a unique id for the message to be able to pair it with with confirmation message
    const imagesUrls = [];
    const fileUrLs = [];

    const messageToSend = await api.createEmptyMessage(
      state.room._id,
    );

    messageToSend.messages = [
      {
        content: data.message,
        timestamp: new Date().toISOString(),
      },
    ];

    messageToSend.status = Constants.PENDING_STATUS;

    commit('addMessage', messageToSend);

    // handle images
    if (data.images != null && data.images.length > 0) {
      for (const image of data.images) {
        const imageData = {
          name: image.name,
          path: image.url,
          originalName: image.name,
        };

        const imageUrl = await api.uploadImageForRoom(imageData, state.room._id, messageToSend._id);
        imagesUrls.push(imageUrl);
      }

      // set images status to uploaded
      // updateMessageImagesStatus(messageId, Constants.UPLOADED_STATUS);
      commit('updateMessageImages', { id: messageToSend._id, images: imagesUrls });
    }

    if (data.files != null && data.files.length > 0) {
      for (const file of data.files) {
        // file is type of File
        const fileData = {
          name: file.name,
          originalName: file.name,
          file,
        };

        const fileUrl = await api.uploadFileForRoom(fileData, state.room._id, messageToSend._id);
        fileUrLs.push(fileUrl);
      }

      // set images status to uploaded
      // updateMessageImagesStatus(messageId, Constants.UPLOADED_STATUS);
      commit('updateMessageFiles', { id: messageToSend._id, files: fileUrLs });
    }

    // // // clear text field
    socketManager.emit('send-message', messageToSend);
  },
  clearError({ commit }) {
    commit('setError', null);
    commit('setErrorParams', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
