<template>
  <div class="flex-grow" v-if="signalDetail">
    <h1 class="py-1 my-px font-bold">{{ signalDetail.tickerName }} - {{ signalDetail.title }}</h1>
    <div class="text-sm text-primary-muted">
      {{ getFormattedDate(signalDetail.createdAt) }} {{ getFormattedTime(signalDetail.createdAt) }}
    </div>
    <div class="p-3 lg:p-5 mt-4 mb-4 border border-primary-main rounded-xl flex flex-wrap lg:flex-nowrap gap-2 lg:gap-6">
      <div class="w-full lg:w-full flex-shrink-0">
        <div class="grid grid-cols-3 gap-4">
          <div class="w-1/2 lg:w-1/5 flex items-center gap-4 flex-shrink-0">
            <div class="flex-grow">
              <div class="text-lg font-bold text-white leading-5">{{ signalDetail.ticker }}</div>
              <div class="text-xs text-primary-muted">{{ signalDetail.tickerName }}</div>
            </div>
          </div>
          <div class="w-full">
            <div class="my-1 lg:my-0 line-clamp-2 leading-5">{{ getMainTickerDescription() }}</div>
          </div>
          <div class="w-full lg:w-auto flex gap-2 flex-shrink-0" v-if="signalDetail.direction == 1">
            <i v-for="i in 5" :key="i" class="icon-bull text-2xl" :class="{ 'text-green-600': i <= signalDetail.severity, 'text-primary-muted': i > signalDetail.severity }"></i>
          </div>
          <div class="w-full lg:w-auto flex gap-2 flex-shrink-0" v-if="signalDetail.direction == -1">
            <i v-for="i in 5" :key="i" class="icon-bear text-2xl" :class="{ 'text-red-600': i <= signalDetail.severity, 'text-primary-muted': i > signalDetail.severity }"></i>
          </div>
        </div>
        <div class="">
          <div class="group py-3.5 flex items-center justify-between cursor-pointer" :class="{ 'text-primary-main': otherTickersDropDown }" @click.prevent="toggleOtherTickersDropDown">
            <span>Other tickers ({{ otherTickers.length }})</span>
            <i class="icon-angle-down text-2xl text-primary-muted group-hover:text-white" :class="{ 'rotate-180': otherTickersDropDown, '!text-primary-main': otherTickersDropDown }"></i>
          </div>
          <transition name="slide-fade">
            <div v-if="otherTickersDropDown" class="mb-3 text-sm text-primary-muted">
              <div v-for="(ticker, index) in otherTickers" :key="index" class="grid grid-cols-3 gap-4 mb-3">
                <div class="w-1/2 lg:w-1/5 flex items-center gap-4 flex-shrink-0">
                  <div class="flex-grow">
                    <div class="text-lg font-bold text-white leading-5">{{ ticker.ticker }}</div>
                    <div class="text-xs text-primary-muted">{{ ticker.name }}</div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="my-1 lg:my-0 line-clamp-2 leading-5">{{ ticker.description }}</div>
                </div>
                <div class="w-full lg:w-auto flex gap-2 flex-shrink-0" v-if="signalDetail.direction == 1">
                  <i v-for="i in 5" :key="i" class="icon-bull text-2xl" :class="{ 'text-green-600': i <= ticker.severity, 'text-primary-muted': i > ticker.severity }"></i>
                </div>
                <div class="w-full lg:w-auto flex gap-2 flex-shrink-0" v-if="signalDetail.direction == -1">
                  <i v-for="i in 5" :key="i" class="icon-bear text-2xl" :class="{ 'text-red-600': i <= ticker.severity, 'text-primary-muted': i > ticker.severity }"></i>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap gap-2 text-sm font-semibold">
      <a :class="['px-4 py-1.5 border rounded-lg hover:brightness-110 active:brightness-95', selectedIndex === 0 ? 'border-primary-main bg-primary-main' : 'border-primary-light bg-primary-light']" href="#" @click.prevent="selectedIndex = 0">
        Source
      </a>
      <a :class="['px-4 py-1.5 border rounded-lg hover:brightness-110 active:brightness-95', selectedIndex === 1 ? 'border-primary-main bg-primary-main' : 'border-primary-light bg-primary-light']" href="#" @click.prevent="openAnalysis()">
        Analysis
      </a>
    </div>
    <div class="mt-4 mb-5" v-if="selectedIndex === 0">
      <div class="prose prose-invert prose-sm max-w-none" v-html="newsDetail.content"></div>
    </div>
    <div class="mt-4 mb-5" v-if="selectedIndex === 1">
      <Loader v-if="isWaitingForAnalysis" />
      <MarkdownRenderer v-if="analysis && !isWaitingForAnalysis" :source="analysis" />
    </div>
    <StartAnalysisModal v-if="isModalVisible" :remainingAnalisys="remainingAnalisys" :startAnalysis="startAnalysis" @close="closeModal" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import * as api from '../api';
import MarkdownRenderer from '../components/MarkdownRenderer.vue';
import StartAnalysisModal from '../components/modal/StartAnalysisModal.vue';
import socketManager from '../socketManager';

export default {
  name: 'SignalDetailScreen',
  components: {
    MarkdownRenderer,
    StartAnalysisModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      signalDetail: null,
      sentimentAnalysis: null,
      newsDetail: null,
      selectedIndex: 0,
      remainingAnalisys: 0,
      analysis: null,
      isLoadingAnalysis: false,
      error: null,
      errorParams: [],
      isModalVisible: false,
      otherTickersDropDown: false,
      otherTickers: [], // This will hold other tickers data
    };
  },
  async created() {
    try {
      this.signalDetail = await api.getSignalDetail(this.id);
    } catch (error) {
      this.error = error;
      this.errorParams = error.params;
    }
    if (this.signalDetail) {
      this.sentimentAnalysis = await api.getSentimentAnalysis(this.signalDetail.sentimentAnalysisId);
      this.loadOtherTickers();
    }

    if (this.sentimentAnalysis) {
      this.newsDetail = await api.getNewsDetailByNewsId(this.sentimentAnalysis.newsId);
    }

    this.remainingAnalisys = await api.getRemainingNewsAnalysis();
  },
  computed: {
    ...mapState(['userSubscription']),
  },
  methods: {
    loadOtherTickers() {
      this.otherTickers = this.sentimentAnalysis.marketTickers.filter((ticker) => ticker.ticker !== this.signalDetail.ticker);
    },
    toggleOtherTickersDropDown() {
      this.otherTickersDropDown = !this.otherTickersDropDown;
    },
    getMainTickerDescription() {
      return this.sentimentAnalysis.marketTickers.find((ticker) => ticker.ticker === this.signalDetail.ticker).description;
    },
    async connectToSocket() {
      await socketManager.connect();
      socketManager.addEventListener('explain-signal', this.handleExplainEvent);
      socketManager.addEventListener('explain-signal-finish', this.handleExplainNewsEvent);
    },
    async startAnalysis() {
      this.closeModal();
      await this.connectToSocket();
      this.selectedIndex = 1;
      socketManager.emit('analyze-signal', this.id);
    },
    handleExplainEvent(data) {
      this.isLoadingAnalysis = true;
      this.analysis = data;
    },
    handleExplainEventFinish(data) {
      this.isLoadingAnalysis = false;
    },
    openAnalysis() {
      if (this.analysis) {
        this.selectedIndex = 1;
      } else {
        this.openModal();
      }
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    getFormattedDate(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    },
    getFormattedTime(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    },
    getColor(event) {
      if (event.indicator === 'holiday' || event.indicator === 'holidays') {
        return 'color: grey';
      }
      if (event.importance === 1) {
        return 'color: red';
      }
      if (event.importance === 0) {
        return 'color: orange';
      }
      return 'color: yellow';
    },
    getValueColor(value) {
      if (value > 0) {
        return 'color: green';
      }
      if (value === 0) {
        return 'color: grey';
      }
      return 'color: red';
    },
  },
};
</script>
 <style>
 .custom-image {
   width: 100%;
   height: auto;
   object-fit: cover;
   max-height: 30vh; /* Adjust this to control the maximum height, representing half the viewport height */
 }
 </style>
