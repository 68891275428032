<template>
  <div class="p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md">
    <div class="relative p-4 lg:p-7 rounded-xl bg-primary-medium text-center">
      <button class="absolute top-2.5 left-2.2 bg-transparent border-0 flex cursor-pointer items-center" @click="onBack()">
        <i class="icon-angle-left text-3xl text-primary-muted hover:brightness-110 active:brightness-95"></i>
        <span class="text-primary-muted">{{ $t('register.back') }}</span>
      </button>
      <div class="my-3 lg:my-5" :user="user">
        <img class="mx-auto" src="../images/logo.svg" alt="">
        <div class="mt-4 text-left">
          <div class="mb-2.5">
            <label class="mb-1 block text-sm text-primary-muted">{{ $t('register.email') }}</label>
            <input v-model="user.email" type="text" class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted" :placeholder="$t('register.emailPlaceholder')">
            <div class="input-errors" v-for="error of v$.user.email.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-2.5">
            <label class="mb-1 block text-sm text-primary-muted">{{ $t('register.firstName') }}</label>
            <input v-model="user.firstName" type="text" class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted" :placeholder="$t('register.firstNamePlaceholder')">
            <div class="input-errors" v-for="error of v$.user.firstName.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-2.5">
            <label class="mb-1 block text-sm text-primary-muted">{{ $t('register.lastName') }}</label>
            <input v-model="user.lastName" type="text" class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted" :placeholder="$t('register.lastNamePlaceholder')">
            <div class="input-errors" v-for="error of v$.user.lastName.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-2.5 flex gap-2.5">
            <div class="w-1/4 flex-shrink-0">
              <label class="mb-1 block text-sm text-primary-muted">{{ $t('register.language') }}</label>
              <select v-model="user.language" class="form-select w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted">
                <option value="EN">{{ $t('languages.english') }}</option>
                <option value="DE">{{ $t('languages.german') }}</option>
                <option value="SK">{{ $t('languages.slovak') }}</option>
                <option value="ES">{{ $t('languages.spanish') }}</option>
                <option value="CS">{{ $t('languages.czech') }}</option>
                <option value="SR">{{ $t('languages.serbian') }}</option>
              </select>
              <div class="input-errors" v-for="error of v$.user.language.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="flex-grow">
              <label class="mb-1 block text-sm text-primary-muted">{{ $t('register.phone') }}</label>
              <vue-tel-input
              styleClasses="test"
                :inputOptions="inputOptions"

                class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg  border-l-0 text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted"
                v-model="user.phone" defaultCountry="SK"></vue-tel-input>
              <div class="input-errors" v-for="error of v$.user.phone.$errors" :key="error.$uid">
                <div class="error-msg">{{ $t(error.$message) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full mt-4 flex flex-col gap-4 items-center font-semibold">
          <button class="w-full px-4 py-1.5 border border-primary-main bg-primary-main rounded-lg hover:brightness-110 active:brightness-95" @click="onSubmit()">{{ $t('register.registerButton') }}</button>
        </div>
      </div>

    </div>
    <showEmailSentModal
    v-if="showEmailSentModal"
    :params="{
      title: $t('emailSent.title'),
      description: $t('emailSent.description'),
      content: $t('emailSent.content'),
      callback: () => onBack()
    }"
  />
  </div>

</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import {
  required, email, minLength, helpers,
} from '@vuelidate/validators';
import { VueTelInput } from 'vue-tel-input';
import * as api from '../api';
import * as utils from '../utils';
import showEmailSentModal from '../components/modal/EmailSentModal.vue';

export default {
  name: 'RegisterScreen',
  components: {
    VueTelInput,
    showEmailSentModal,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      inputOptions: {
        showDialCode: false,
        placeholder: this.$t('register.phonePlaceholder'),
        styleClasses: ['test'],
      },
      user: {
        email: null,
        firstName: null,
        lastName: null,
        language: null,
        phone: '',
      },
      showEmailSentModal: false,
    };
  },
  validations: {
    user: {
      email: {
        email,
        required,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      language: {
        required,
      },
      phone: {
        minLength: helpers.withMessage('register.phoneError', minLength(9)),
      },
    },
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
    async onSubmit() {
      console.log('Register');

      this.v$.$validate();
      if (this.v$.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        console.log(this.user);

        try {
          await api.registerUser(this.user);
          this.showEmailSentModal = true;
        } catch (e) {
          console.error(e);
          this.toast.error(this.$t('errors.INTERNAL_ERROR.message'));
        }
      }
    },
  },
};
</script>

<style>
.input-errors {
  color: red;
}
.test {
  background-color: #E6E7E8 !important;
  border: unset !important;
}
.vue-tel-input:focus-within {
  box-shadow: unset !important;
}

</style>
