<template>
  <div class="w-full lg:max-w-72 flex-shrink-0">
    <div class="text-sm text-white flex flex-wrap items-center justify-between gap-1">
      <h3 class="py-1 my-px font-bold">{{ $t('watchers.title') }}</h3>
      <button
        :class="{
          'bg-primary-main text-white': addWatcher,
          'bg-primary-light text-primary-muted cursor-not-allowed': watchers.length >= MAX_WATCHERS,
          'text-primary-main hover:bg-primary-main hover:text-white': watchers.length < MAX_WATCHERS && !addWatcher
        }"
        class="px-4 py-2 cursor-pointer rounded-lg"
        @click="toggleAddWatcher"
        :disabled="watchers.length >= MAX_WATCHERS"
      >
        {{ $t('watchers.add_button') }}
      </button>
    </div>
    <div v-if="!addWatcher" class="mt-1 lg:mt-4 rounded-xl text-center">
      <!-- Check if there are watchers -->
      <div v-if="watchers.length > 0" class="w-full grid grid-cols-1 sm:grid-cols-2 gap-2 text-sm font-semibold">
        <div v-for="(watcher, index) in watchers" :key="index" class="w-full border border-primary-main relative px-2 py-1.5 rounded-lg flex items-center justify-between">
          <div class="text-white font-semibold" @click.prevent="selectWatcher(watcher._id)">
            {{ watcher.symbol }}
          </div>
          <button
            class="bg-transparent border-0 text-primary-muted hover:text-white cursor-pointer"
            @click.prevent="openDeleteModal(watcher._id)"
          >
            <i class="icon-trash text-lg text-red-600"></i>
          </button>
        </div>
      </div>
      <!-- Display "No watchers found" message if there are no watchers -->
      <div v-else>
        {{ $t('watchers.no_watchers') }}
      </div>
    </div>

    <div v-if="addWatcher">
      <form class="relative mt-4 w-full">
        <input v-model="search" type="search" class="form-input w-full h-10 pr-10 pl-2 bg-primary-medium border-primary-medium rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted" :placeholder="$t('watchers.search_placeholder')">
        <button
          @click.prevent="searchSymbols"
          type="submit" class="absolute top-1/2 right-2 -translate-y-1/2 flex bg-transparent border-0 rounded-lg text-primary-muted cursor-pointer hover:brightness-110 active:brightness-95"
        >
          <i class="icon-search text-2xl"></i>
        </button>
      </form>
      <div class="mt-4 p-4 rounded-xl bg-primary-medium">
        <!-- Display remaining number of watchers to add -->
        <div class="text-white text-sm mb-4">
          {{ $t('watchers.remaining') }} <span class="font-bold">{{ remainingWatchers }}</span> {{ $t('watchers.more_watchers') }}
        </div>
        <div v-for="(group, index) in symbols" :key="index" class="-mt-3 mb-4">
          <div class="border-b border-b-primary-light">
            <div
              class="group py-3.5 flex items-center justify-between font-bold text-sm cursor-pointer"
              :class="{ 'text-primary-main' : group.dropdown }"
              @click.prevent="toggleDropdown(group)"
            >
              <span>
                {{ group.type }} ({{ group.symbols.length }})
                <span v-if="selectedCount(group)" class="text-xs text-primary-bright">
                  ({{ selectedCount(group) }} {{ $t('watchers.selected') }})
                </span>
              </span>
              <i
                class="icon-angle-down text-2xl text-primary-muted group-hover:text-white"
                :class="{ 'rotate-180' : group.dropdown, '!text-primary-main' : group.dropdown }"
              ></i>
            </div>
            <transition name="fade">
              <div v-if="group.dropdown">
                <div class="mb-3 text-sm text-primary-muted">
                  <div class="flex flex-col gap-2.5">
                    <div
                      v-for="(symbol, symIndex) in group.symbols" :key="symIndex"
                      class="flex items-center gap-2 cursor-pointer rounded-lg px-3 py-2"
                      :class="{ 'bg-primary-light': isSelected(symbol._id) }"
                      @click="toggleSelection(symbol)"
                    >
                      <div class="min-w-px text-sm text-white flex flex-wrap items-center justify-between gap-1">
                        <div class="text-sm uppercase text-white font-semibold">{{ symbol.symbol }}</div>
                        <div class="text-sm text-primary-muted truncate">{{ symbol.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <button
          class="w-full h-10 px-7 inline-flex items-center justify-center rounded-lg font-semibold hover:brightness-110 active:brightness-95"
          :class="{'bg-primary-main text-white': selectedSymbols.length > 0, 'bg-primary-light text-primary-muted cursor-not-allowed': selectedSymbols.length === 0}"
          :disabled="selectedSymbols.length === 0"
          @click.prevent="createWatchers"
        >
          {{ $t('watchers.create_button') }}
        </button>
      </div>
    </div>
    <ConfirmationModal
      v-if="isModalOpen"
      :title="$t('watchers.delete_title')"
      :message="$t('watchers.delete_message')"
      :confirmText="$t('watchers.delete_confirm')"
      :cancelText="$t('watchers.delete_cancel')"
      :confirmAction="deleteWatcher"
      @close="isModalOpen = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useToast } from 'vue-toastification';
import * as api from '../api';
import ConfirmationModal from './modal/ConfirmModal.vue';

export default {
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      isModalOpen: false,
      MAX_WATCHERS: 0,
      symbols: [],
      watchers: [],
      loading: true,
      addWatcher: false,
      search: '',
      selectedSymbols: [], // Array to store selected symbols
      watcherId: null,
    };
  },
  components: {
    ConfirmationModal,
  },
  mounted() {
    this.fetchWatchers();
    this.fetchActiveProduct();
  },
  methods: {
    async deleteWatcher() {
      try {
        await api.deleteWatcher(this.watcherId);
        this.fetchWatchers();
        this.isModalOpen = false;
        this.toast.success(this.$t('watchers.delete_success'));
      } catch (error) {
        console.error(error);
      }
    },
    openDeleteModal(id) {
      this.watcherId = id;
      this.isModalOpen = true;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    toggleDropdown(group) {
      group.dropdown = !group.dropdown;
    },
    toggleMoreOptions(symbol) {
      symbol.moreOptionsVisible = !symbol.moreOptionsVisible;
    },
    toggleAddWatcher() {
      this.addWatcher = !this.addWatcher;
    },
    async fetchActiveProduct() {
      try {
        const product = await api.getActiveProduct();
        this.MAX_WATCHERS = product.watcherLimit;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchWatchers() {
      this.loading = true;
      try {
        this.watchers = await api.getWatchers();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async createWatchers() {
      try {
        await api.createWatchers(this.selectedSymbols);
        this.fetchWatchers();
        this.toggleAddWatcher();
        this.cleanSearch();
        this.toast.success(this.$t('watchers.create_success'));
      } catch (error) {
        console.error(error);
      }
    },
    async searchSymbols() {
      try {
        const symbols = await api.searchSymbols(this.search);
        const groupedSymbols = symbols.reduce((acc, symbol) => {
          const { type } = symbol;
          if (!acc[type]) {
            acc[type] = {
              type,
              symbols: [],
            };
          }
          acc[type].symbols.push(symbol);
          return acc;
        }, {});

        this.symbols = Object.values(groupedSymbols);
      } catch (error) {
        console.error(error);
      }
    },
    cleanSearch() {
      this.search = '';
      this.symbols = [];
    },
    toggleSelection(symbol) {
      const index = this.selectedSymbols.findIndex((s) => s._id === symbol._id);
      if (index === -1) {
        // Check if maximum limit is reached
        if (this.selectedSymbols.length < this.MAX_WATCHERS) {
          this.selectedSymbols.push(symbol);
        } else {
          alert(this.$t('watchers.max_limit_alert', { limit: this.MAX_WATCHERS }));
        }
      } else {
        this.selectedSymbols.splice(index, 1);
      }
    },
    isSelected(id) {
      return this.selectedSymbols.some((s) => s._id === id);
    },
    selectedCount(group) {
      return group.symbols.filter((s) => this.selectedSymbols.some((sel) => sel._id === s._id)).length;
    },
  },
  computed: {
    remainingWatchers() {
      return this.MAX_WATCHERS - this.selectedSymbols.length - this.watchers.length;
    },
  },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
