<template>
  <SongCreateBoard></SongCreateBoard>
</template>
<script>
import SongCreateBoard from './SongCreateBoard.vue';

export default {
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
  components: {
    SongCreateBoard,
  },
};
</script>
