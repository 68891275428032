import { createStore } from 'vuex';
import {
  getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signInWithEmailAndPassword, reauthenticateWithCredential, EmailAuthProvider, updatePassword,
} from 'firebase/auth';
import * as api from '../api';
import { i18n } from '../i18n';

// MODULES
import chat from './modules/chat';
import news from './modules/news';

// Mutations
export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_INFO_LOADING = 'SET_USER_INFO_LOADING';
export const SET_USER_SUB_INFO = 'SET_USER_SUB_INFO';
export const SET_GURU_ROOM = 'SET_GURU_ROOM';
export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT';

// Actions
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';

export default createStore({
  state: {
    userInfoLoading: false,
    userInfo: null,
    userSubscription: null,
    guru: null,
  },
  getters: {
    email(state) {
      return state.userInfo?.email;
    },
  },
  mutations: {
    [CLEAR_STATE]: (state) => {
      state.userInfo = null;
      state.userSubscription = null;
    },
    [SET_USER_INFO]: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    [SET_USER_SUB_INFO]: (state, userSubscription) => {
      state.userSubscription = userSubscription;
    },
    [SET_USER_INFO_LOADING]: (state, userInfoLoading) => {
      state.userInfoLoading = userInfoLoading;
    },
    [SET_GURU_ROOM]: (state, guru) => {
      state.guru = guru;
    },
  },
  actions: {
    async [USER_INFO_REQUEST]({ commit }) {
      const userInfo = await api.getUserInfo();
      try {
        const userSubscription = await api.getUserSubscription();
        commit(SET_USER_SUB_INFO, userSubscription);
      } catch (e) {
        console.log(e);
      }

      try {
        const guru = await api.getRoomByRelatedId('guru');
        console.log(guru);
        commit(SET_GURU_ROOM, guru);
      } catch (e) {
        console.log(e);
      }

      commit(SET_USER_INFO, userInfo);
    },
    async logOut({ commit }) {
      await getAuth().signOut();
      commit(CLEAR_STATE);
    },
    async emailSignIn({ dispatch }, { email, password }) {
      // firebase email password sign in
      const signIn = await signInWithEmailAndPassword(getAuth(), email, password);
      console.log(signIn);
      await dispatch(USER_INFO_REQUEST);
    },
    async changePassword({ dispatch }, { oldPassword, newPassword }) {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw new Error('No user is currently signed in.');

      // Re-authenticate the user with the old password
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);

      // Update the password
      await updatePassword(user, newPassword);

      // Fetch the updated user info
      await dispatch(USER_INFO_REQUEST);
    },
    async googleSignIn({ dispatch }) {
      const provider = new GoogleAuthProvider();
      const signIn = await signInWithPopup(getAuth(), provider);
      // if is new user call register-confirm
      if (signIn._tokenResponse.isNewUser) {
        // await api.registerConfirm();
        // get new token
        await getAuth().currentUser.getIdToken(true);
      }

      await dispatch(USER_INFO_REQUEST);
    },
    async appleSignIn({ dispatch }) {
      const provider = new OAuthProvider('apple.com');
      const signIn = await signInWithPopup(getAuth(), provider);
      if (signIn._tokenResponse.isNewUser) {
        await api.registerConfirm();
        // get new token
        await getAuth().currentUser.getIdToken(true);
      }
      await dispatch(USER_INFO_REQUEST);
    },
    async updateUserLanguage({ commit, dispatch }, language) {
      // Update the user's language in the Vuex state
      commit('setUserLanguage', language);

      // Optionally, save the language to the server or local storage
      // this.$http.post('/user/language', { language });
      // call api changeLanguage
      await api.changeLanguage(language);

      // Update the locale of your i18n plugin, if you're using one
      i18n.global.locale.value = language; // Notice the .value part, this is crucial in composition API mode

      // Store the language in local storage
      localStorage.setItem('language', language);

      console.log('Locale updated to:', i18n.global.locale.value);

      // If you're using local storage to persist the language
      localStorage.setItem('language', language);

      // reload user info
      await dispatch(USER_INFO_REQUEST);
    },
  },
  modules: {
    chat,
    news,
  },
});
