/* eslint-disable no-shadow */
import socketManager from '../../socketManager';
import * as api from '../../api';
import * as Constants from '../../constants';

const state = {
  analysis: null,
  isLoadingAnalysis: false,
  error: null,
  errorParams: [],
};

const getters = {
  getAnalysis: (state) => state.analysis,
  isLoadingAnalysis: (state) => state.isLoadingAnalysis,
  error: (state) => state.error,
  errorParams: (state) => state.errorParams,
};

const mutations = {
  setAnalysis(state, analysis) {
    state.analysis = analysis;
  },
  setLoadingAnalysis(state, isLoading) {
    state.isLoadingAnalysis = isLoading;
  },
  setError(state, error) {
    state.error = error;
  },
  setErrorParams(state, errorParams) {
    state.errorParams = errorParams;
  },
};

const actions = {
  async fetchAnalysis({ commit }, newsId) {
    commit('setLoadingAnalysis', true);
    try {
      const response = await api.getNewsAnalysis(newsId);
      // if response is "" then set analysis to null
      if (response === '') {
        commit('setAnalysis', null);
      } else {
        commit('setAnalysis', response);
      }
    } catch (error) {
      commit('setError', error);
      commit('setErrorParams', error.response.data);
    } finally {
      commit('setLoadingAnalysis', false);
    }
  },
  async connectToSocket({ commit, dispatch }) {
    console.log('connectToSocket');

    await socketManager.connect();
    socketManager.addEventListener('explain-news', (data) => dispatch('handleExplainNews', data));
    socketManager.addEventListener('explain-news-finish', (data) => dispatch('handleExplainNewsFinish', data));
    // commit('setLoadingMessages', false);
  },
  async startAnalysis({ commit }, newsId) {
    console.log('startAnalysis');
    console.log(newsId);
    socketManager.emit('analyze-news', newsId);
  },
  async handleExplainNews({ commit }, data) {
    console.log('handleExplainNews');
    console.log(data);
    commit('setLoadingAnalysis', true);
    commit('setAnalysis', data);
  },
  async handleExplainNewsFinish({ commit }, data) {
    console.log('handleExplainNewsFinish');
    console.log(data);
    commit('setLoadingAnalysis', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
