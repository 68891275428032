<template>
  <header class="my-3 md:my-5 lg:my-7">
    <div class="w-full max-w-main mx-auto px-3">
      <div class="flex flex-wrap lg:flex-nowrap items-center gap-3">
        <a class="flex mr-auto hover:brightness-110" href=""><img src="../images/logo.svg" alt=""></a>
        <div class="relative">
  <button
    class="order-1 relative w-30 h-10 flex items-center justify-center bg-primary-medium border-0 rounded-lg text-primary-muted cursor-pointer hover:bg-primary-main hover:text-white active:brightness-95"
    @click.prevent="toggleLanguages"
  >
    <div class="text-sm text-white px-2">
      <country-flag :country="getFlag(selectedLanguage)" size="normal" class="rounded"/>
    </div>
  </button>

  <OnClickOutside @trigger="closeLanguage">
    <ul class="absolute top-full left-1/4 -translate-x-2/3 sm:translate-x-0 sm:left-0 w-[280px] mt-1 py-2 flex flex-col rounded-lg shadow shadow-black bg-primary-medium z-20 text-sm font-medium"
        v-if="showLanguageDropdown"
    >
      <li v-for="lang in languages" :key="lang.code">
        <a class="px-4 py-2 block no-underline text-primary-muted hover:bg-primary-light/20 " href="#" @click.prevent="changeLanguage(lang.code)">
          <div
      :class="[
        'text-lg',
        lang.code === selectedLanguage ? 'text-primary font-bold' : 'text-white',
      ]"
    >
            <country-flag :country="lang.flag" size="normal" class="rounded"/>&emsp;{{ lang.name }}
          </div>
        </a>
      </li>
    </ul>
  </OnClickOutside>
</div>
<Notifications />

        <form class="order-6 lg:order-2 relative w-full lg:max-w-52 hidden lg:block" :class="{ '!block': search }" @submit.prevent="searchData">
          <input v-model="searchValue"
            type="search" class="form-input w-full h-10 pl-10 pr-2 bg-primary-medium border-primary-medium rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted" :placeholder="$t('header.searchPlaceholder')">
          <button type="submit" class="absolute top-1/2 left-2 -translate-y-1/2 flex bg-transparent border-0 rounded-lg text-primary-muted cursor-pointer hover:brightness-110 active:brightness-95"
            @click="searchData"
          ><i class="icon-search text-2xl"></i></button>
        </form>
        <a class="order-3 group hidden lg:flex items-center gap-3 active:brightness-95" href="#" @click="logout">
          <span class="w-10 h-10 flex items-center justify-center bg-primary-medium border-0 rounded-lg text-primary-muted cursor-pointer group-hover:bg-primary-main group-hover:text-white"><i class="icon-logout text-2xl"></i></span>
          <span>{{ $t('header.logout') }}</span>
        </a>
        <button class="order-4 w-10 h-10 flex lg:hidden items-center justify-center bg-primary-medium border-0 rounded-lg text-primary-muted cursor-pointer hover:bg-primary-main hover:text-white active:brightness-95" :class="{ 'bg-primary-main text-white': search }" @click.prevent="search = !search"><i class="icon-search text-2xl"></i></button>
        <button class="order-5 w-10 h-10 flex lg:hidden items-center justify-center bg-primary-medium border-0 rounded-lg text-primary-muted cursor-pointer hover:bg-primary-main hover:text-white active:brightness-95" :class="{ 'bg-primary-main text-white': mobileNav }"   @click.prevent="handleMenuIconClick"><i class="icon-menu-line text-2xl"></i></button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import { OnClickOutside } from '@vueuse/components';
import * as api from '../api';
import Notifications from './Notifications.vue';

export default {
  data() {
    return {
      notifications: [],
      searchValue: '',
      mobileNav: false,
      search: false,
      showLanguageDropdown: false,
      showNotificationsDropdown: false,
      isClickInside: false,
      languages: [
        { code: 'en', name: 'English', flag: 'GB' },
        { code: 'sk', name: 'Slovenský', flag: 'SK' },
        { code: 'de', name: 'Deutsch', flag: 'DE' },
        { code: 'es', name: 'Español', flag: 'ES' },
        { code: 'cs', name: 'Česky', flag: 'CZ' },
        { code: 'sr', name: 'Srpski', flag: 'RS' },
      ],
    };
  },
  components: {
    OnClickOutside,
    Notifications,
  },
  mounted() {
    this.emitter.on('mobileNav', (evt) => {
      console.log('mobile nav', evt.eventContent);

      // If the eventContent does not match the current mobileNav state, update it
      if (this.mobileNav !== evt.eventContent) {
        this.toggleMobileNav(evt.eventContent);
      }
    });
  },
  computed: {
    ...mapState(['userInfo']),

    selectedLanguage() {
      return this.userInfo?.language || 'en'; // Default to 'en' if no language is set
    },
  },
  methods: {
    toggleNotifications() {
      this.showNotificationsDropdown = !this.showNotificationsDropdown;
    },
    closeNotifications() {
      this.showNotificationsDropdown = false;
    },
    toggleLanguages() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    closeLanguage() {
      this.showLanguageDropdown = false;
    },
    changeLanguage(code) {
      this.$store.dispatch('updateUserLanguage', code);
      this.closeLanguage();
      // reload the page to apply the new language
    },
    getFlag(language) {
      const lang = this.languages.find((l) => l.code === language);
      return lang ? lang.flag : 'GB';
    },
    handleMenuIconClick() {
      console.log('handle menu icon click');
      this.mobileNav = !this.mobileNav;
      this.emitter.emit('mobileNav', { eventContent: this.mobileNav });
    },
    toggleMobileNav(state) {
      console.log('toggle mobile nav');
      this.mobileNav = state;
    },
    async logout() {
      console.log('logout');
      await this.$store.dispatch('logOut');
      this.$router.push({ name: 'login' });
      this.hideMenu();
    },
    cleanSearch() {
      console.log('clean search');
      this.searchValue = '';
      this.searchData();
      console.log('search', this.searchValue);
    },
    searchData() {
      if (this.$route.name === 'topics') {
        // if search is empty, redirect to topics without search query
        if (this.searchValue === '') {
          this.$router.push({ name: 'topics', query: null });
        } else {
          this.$router.push({ name: 'topics', query: { search: this.searchValue } });
        }
      } else if (this.searchValue === '') {
        // remove search query from url
        this.$router.push({ name: this.$route.name, query: null });
      } else {
        this.$router.push({ name: 'topics', query: { search: this.searchValue } });
      }
    },
  },
};
</script>
