<template>
  <div class="p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md">
      <div class="relative p-4 lg:p-7 rounded-xl bg-primary-medium text-center">
        <button class="absolute top-2.5 left-2.2 bg-transparent border-0 flex cursor-pointer items-center" @click="onBack()">
          <i class="icon-angle-left text-3xl text-primary-muted hover:brightness-110 active:brightness-95"></i>
          <span class="text-primary-muted">{{ $t('forgotPassword.back') }}</span>
        </button>
        <div class="my-3 lg:my-5">
          <img class="mx-auto" src="../images/logo.svg" alt="">
          <div class="my-4 text-lg font-bold">
            {{ $t('forgotPassword.title') }}
          </div>
          <div class="text-left">
            <div class="mb-2.5">
              <label class="mb-1 block text-sm text-primary-muted">{{ $t('forgotPassword.emailLabel') }}</label>
              <input v-model="userEmail" type="text" class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted" :placeholder="$t('forgotPassword.emailPlaceholder')">
              <div class="input-errors" v-for="error of v$.userEmail.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
            </div>
          </div>
          <div class="w-full mt-4 flex flex-col gap-4 items-center font-semibold">
            <button class="w-full px-4 py-1.5 bg-primary-main rounded-lg hover:brightness-110 active:brightness-95" @click="onSubmit()">{{ $t('forgotPassword.submitButton') }}</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import * as api from '../api';
import * as utils from '../utils';

export default {
  name: 'ForgotPasswordScreen',
  setup() {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    };
  },
  validations: {
    userEmail: {
      email,
      required,
    },
  },
  data() {
    return {
      userEmail: null,
    };
  },
  methods: {
    async onSubmit() {
      this.v$.$validate();
      if (this.v$.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        try {
          await api.resetPassword(this.userEmail);
          this.toast.success(this.$t('forgotPassword.success'));
          this.$router.push('/login');
        } catch (error) {
          this.toast.error(this.$t('forgotPassword.error'));
        }
      }
      console.log('Forgot Password');
    },
    onBack() {
      this.$router.go(-1);
    },
  },
};
</script>
