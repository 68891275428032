<template>
  <div class="flex-grow" v-if="newsDetail">
    <h1 class="py-1 my-px font-bold">{{ newsDetail.title }}</h1>
    <div class="text-sm text-primary-muted">
      {{ getFormattedDate(newsDetail.published) }} {{ getFormattedTime(newsDetail.published) }} / Published by
      <a class="text-primary-main underline hover:no-underline" href="">{{ newsDetail.source }}</a>
    </div>
    <img class="w-full my-4 rounded-xl block custom-image" :src="newsDetail.imageUrl" :srcset="`${newsDetail.imageUrl} 1x, ${newsDetail.imageUrl} 2x`" alt="">
    <div class="flex flex-wrap gap-2 text-sm font-semibold">
      <a
        :class="['px-4 py-1.5 border rounded-lg hover:brightness-110 active:brightness-95', selectedIndex === 0 ? 'border-primary-main bg-primary-main' : 'border-primary-light bg-primary-light']"
        href="#"
        @click.prevent="selectedIndex = 0"
      >
        News
      </a>
      <a
        :class="['px-4 py-1.5 border rounded-lg hover:brightness-110 active:brightness-95', selectedIndex === 1 ? 'border-primary-main bg-primary-main' : 'border-primary-light bg-primary-light']"
        href="#"
        @click.prevent="openAnalysis()"
      >
        Analysis
      </a>
    </div>
    <div class="mt-4 mb-5" v-if="selectedIndex === 0">
      <div class="prose prose-invert prose-sm max-w-none" v-html="newsDetail.content"></div>
    </div>
    <div class="mt-4 mb-5" v-if="selectedIndex === 1">
      <Loader v-if="isWaitingForAnalysis"/>
      <MarkdownRenderer v-if="analysis && !isWaitingForAnalysis" :source="analysis" />
    </div>

    <StartAnalysisModal
      v-if="isModalVisible"
      :remainingAnalisys="remainingAnalisys"
      :startAnalysis="startAnalysis"
      @close="closeModal"
    />
  </div>
</template>

<script>
import StartAnalysisModal from '../components/modal/StartAnalysisModal.vue';
import MarkdownRenderer from '../components/MarkdownRenderer.vue';
import * as api from '../api';
import socketManager from '../socketManager';
import Loader from '../components/LoaderComp.vue';

export default {
  name: 'NewsDetailScreen',
  props: {
    id: {
      type: String,
      required: true,
    },
    nextId: {
      type: String,
      required: false,
    },
    previousId: {
      type: String,
      required: false,
    },
  },
  components: {
    StartAnalysisModal,
    MarkdownRenderer,
    Loader,
  },
  data() {
    return {
      newsDetail: null,
      analysis: null,
      isWaitingForAnalysis: false,
      isLoadingAnalysis: false,
      error: null,
      errorParams: [],
      remainingAnalisys: 0,
      isModalVisible: false,
      selectedIndex: 0,
    };
  },
  async created() {
    try {
      this.newsDetail = await api.getNewsDetail(this.id);
      this.remainingAnalisys = await api.getRemainingNewsAnalysis();
      this.analysis = await api.getNewsAnalysis(this.newsDetail.id);
    } catch (error) {
      console.error('Error fetching news detail or remaining analysis:', error);
    }
  },
  methods: {
    async connectToSocket() {
      console.log('connectToSocket');
      await socketManager.connect();
      socketManager.addEventListener('explain-news', this.handleExplainNews);
      socketManager.addEventListener('explain-news-finish', this.handleExplainNewsFinish);
    },
    async startAnalysis() {
      this.closeModal();
      this.isWaitingForAnalysis = true;
      await this.connectToSocket();
      this.selectedIndex = 1;
      socketManager.emit('analyze-news', this.newsDetail.id);
    },
    handleExplainNews(data) {
      console.log('handleExplainNews');
      this.isWaitingForAnalysis = false;
      this.isLoadingAnalysis = true;
      this.analysis = data;
    },
    handleExplainNewsFinish(data) {
      console.log('handleExplainNewsFinish');
      this.isLoadingAnalysis = false;
    },
    openAnalysis() {
      if (this.analysis) {
        this.selectedIndex = 1;
      } else {
        this.openModal();
      }
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    getFormattedDate(date) {
      date = typeof date === 'number' ? new Date(date * 1000) : new Date(date);
      return date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    },
    getFormattedTime(date) {
      date = typeof date === 'number' ? new Date(date * 1000) : new Date(date);
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    },
  },
};
</script>

<style>
.custom-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 30vh; /* Adjust this to control the maximum height, representing half the viewport height */
}
</style>
