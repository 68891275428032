<template>
  <div class="fixed top-0 left-0 w-full h-full p-3 overlay z-50">
    <div class="p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md">
      <div class="relative p-4 lg:p-7 rounded-xl bg-primary-medium text-center">
        <button class="absolute top-2.5 right-2.5 bg-transparent border-0 flex cursor-pointer" @click="closeModal">
          <i class="icon-close text-3xl text-primary-muted hover:brightness-110 active:brightness-95"></i>
        </button>
        <div class="my-3 lg:my-5">
          <div class="text-lg font-bold">
            {{ $t('deleteAccountModal.confirmationTitle') }}
          </div>
          <div class="text-sm text-primary-main">
            {{ $t('deleteAccountModal.irreversibleWarning') }}
          </div>
          <div class="w-60 mt-4 mx-auto flex flex-gap gap-4 items-center font-semibold">
            <button class="w-full px-4 py-1.5 border border-primary-main bg-primary-main rounded-lg hover:brightness-110 active:brightness-95" @click="closeModal">
              {{ $t('deleteAccountModal.backButton') }}
            </button>
            <button class="w-full px-4 py-1.5 border border-danger-main bg-danger-main rounded-lg hover:brightness-110 active:brightness-95" @click="deleteAccount">
              {{ $t('deleteAccountModal.deleteButton') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteAccountModal',
  props: {
    deleteAccount: {
      type: Function,
      required: true,
    },
  },
  methods: {
    closeModal() {
      console.log('Close modal');
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.border-danger-main {
  border-color: #FF4D4F;
}
.bg-danger-main {
  background-color: #FF4D4F;
}
</style>
