<template>
<body >
  <div class="min-h-screen flex flex-col">
    <HeaderComp/>
    <main class="flex-grow mb-5 lg:mb-7">
      <div class="w-full max-w-main mx-auto px-3">
        <div class="flex flex-col lg:flex-row gap-3 lg:gap-7">
          <SidebarComp/>
          <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
              <component :is="Component" :key="route.path"  />
            </transition>
          </router-view>
        </div>
      </div>
    </main>
    <FooterComp/>
  </div>
</body>
</template>

<script>
import HeaderComp from '../components/HeaderComp.vue';
import FooterComp from '../components/FooterComp.vue';
import SidebarComp from '../components/SidebarComp.vue';

export default {
  components: {
    HeaderComp,
    FooterComp,
    SidebarComp,
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
