<template>
  <div
    class="mt-2 container gap-5 rounded-xl flex flex-wrap lg:flex-nowrap lg:items-center hover:bg-primary-medium show-play-on-hover"
  >
    <div
      @click="playAudio"
      @mouseover="isHover = true"
      @mouseout="isHover = false"
      class="group relative w-20 h-20 flex-shrink-0 bg-primary-medium rounded-xl overflow-hidden cursor-pointer"
    >
      <img class="w-full h-full" :src="song.image_url" />

      <div class="absolute center forward overlay">
        <Vue3Lottie
          v-if="isPlaying && !isHover"
          class="overlay-shown icon"
          :animationData="AstronautJSON"
          :height="40"
          :width="40"
        />
        <i class="fa-solid fa-play icon" v-if="!isPlaying"></i>
        <i class="fa-solid fa-pause icon" v-if="isPlaying && isHover"></i>
      </div>
      <audio ref="audio" :src="song.audio_url"></audio>
    </div>
    <div class="flex items-center gap-4 flex-shrink-0">
      <div class="flex-grow">
        <div class="text-lg font-bold text-white leading-5">
          {{ song.title }}
        </div>
        <div class="text-xs text-primary-muted">{{ song.tags }}</div>
      </div>
    </div>
    <div
      class="w-1/2 lg:w-1/5 text-sm text-primary-muted flex-shrink-0 text-right lg:text-left"
    >
      <div>{{ getFormattedDate(song.created_at) }}</div>
      <div>{{ getFormattedTime(song.created_at) }}</div>
    </div>
    <div class="w-full">
      <div class="my-1 lg:my-0 line-clamp-2 leading-5">{{ song.title }}</div>
    </div>
  </div>
</template>

<script>
import AstronautJSON from '../../../images/sound-animation.json';

export default {
  name: 'SongComp',
  props: {
    song: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPlaying: false,
      AstronautJSON,
    };
  },
  methods: {
    playAudio() {
      const audio = this.$refs.audio;
      console.log(audio.play());
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
        audio.onended = () => {
          this.isPlaying = false;
        };
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    },
    getFormattedDate(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    getFormattedTime(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    },
  },
};
</script>

<style scoped>
.show-play-on-hover {
  transition: all 0.3s;
}
.forward {
  z-index: 2000;
}
/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
}

/* Make the image to responsive */
.image {
  width: 100%;
  height: auto;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #000;
}

/* When you mouse over the container, fade in the overlay icon*/
.container:hover .overlay {
  opacity: 1;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/* When you move the mouse over the icon, change color */
.fa-user:hover {
  color: #eee;
}
</style>
