<template>
  <div v-if="messageGroup"
  @mouseover="showTooltip = true"
  @mouseleave="showTooltip = false"
  >
    <div class="flex gap-3 lg:gap-5 mb-4" v-if="messageGroup.direction == 'incoming'">
      <div class="w-10 lg:w-12 flex-shrink-0">
        <div class="relative w-10 h-10 lg:w-12 lg:h-12 bg-primary-medium rounded-xl overflow-hidden flex items-center justify-center">
          <img src="../../images/bull-avatar.svg" alt="">
        </div>
      </div>
      <div class="flex-grow ">
        <div class="flex items-center gap-2">
          <span class="font-bold">Berry - {{topicLabel}}</span>
          <span class="text-xs lg:text-sm text-primary-muted">{{getDateTime(messageGroup.timestamp)}}</span>
        </div>
        <div class="mt-2 text-sm relative"
        >
          <div class="w-36 lg:w-36 flex-shrink-0" v-for="image in messageGroup.images" :key="image">
            <div class="relative w-36 h-36 lg:w-36 mb-2 lg:h-36 bg-primary-medium rounded-xl overflow-hidden flex items-center justify-center">
              <Image :src="image" alt="" preview />
            </div>
          </div>
          <div class="w-1/3 lg:w-36 flex-shrink-0" v-for="attachment in messageGroup.attachments" :key="attachment"> 
            <!-- if attachment type is audio -->
            <!-- fillhere -->
            <div v-if="attachment.type === 'audio'" class="relative w-full h-36 lg:w-36 mb-2 lg:h-36 bg-primary-medium rounded-xl overflow-hidden flex items-center justify-center">
              <audio
              :src="attachment.audio.url"
              controls
              preload="auto"
              class="w-full player"
              >
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
          <div ref="messageContainer" class="p-3 lg:p-4 inline-block bg-primary-medium rounded-xl rounded-tl-none w-full w-max-800px"
            v-for="message in messageGroup.messages" :key="message.id">
            <MarkdownRenderer :source="processContent(message.content)" />
        <div class="mt-1 text-sm" v-if="messageGroup.action">
          <div class="inline-block rounded-xl rounded-tl-none text-primary-muted">
            <i class="icon-loading inline-block align-middle text-2xl animate-spin"></i>{{ getActionText(messageGroup.action)}}
          </div>
        </div>
          </div>

          <div v-if="showTooltip && !isLast" class="absolute width-10 left-2 bottom-0 group flex items-center mt-3">
            <span
              class="w-8 h-8 lg:w-8 lg:h-8 flex items-center justify-center border-0 rounded-lg cursor-pointer hover:bg-primary-medium hover:text-white text-white lg:text-primary-muted"
              @click="copyMessage(messageGroup.messages)">
              <i :class="{
                'fa-regular fa-copy text-lg': !copied,
                'fa-solid fa-check text-lg text-primary-main': copied,
              }"></i>
            </span>
            <span
              class="w-8 h-8 lg:w-8 lg:h-8 flex items-center justify-center border-0 rounded-lg cursor-pointer hover:bg-primary-medium hover:text-white text-white lg:text-primary-muted"
              @click="likeMessage(messageGroup)">
              <i :class="{
                'fa-regular fa-thumbs-up text-lg ': !liked,
                'fa-solid fa-thumbs-up text-lg text-primary-main': liked,
              }"></i>
            </span>
          </div>
          <div v-if="isLast" class="group flex items-center mt-3">
            <span
              class="w-8 h-8 lg:w-8 lg:h-8 flex items-center justify-center border-0 rounded-lg cursor-pointer hover:bg-primary-medium hover:text-white text-white lg:text-primary-muted"
              @click="copyMessage(messageGroup.messages)">
              <i :class="{
                'fa-regular fa-copy text-lg': !copied,
                'fa-solid fa-check text-lg text-primary-main': copied,
              }"></i>
            </span>
            <span
              class="w-8 h-8 lg:w-8 lg:h-8 flex items-center justify-center border-0 rounded-lg cursor-pointer hover:bg-primary-medium hover:text-white text-white lg:text-primary-muted"
              @click="likeMessage(messageGroup)">
              <i :class="{
                'fa-regular fa-thumbs-up text-lg ': !liked,
                'fa-solid fa-thumbs-up text-lg text-primary-main': liked,
              }"></i>
            </span>
          </div>
        </div>

      </div>
    </div>

    <div class="flex items-start mt-3 gap-2.5 text-xs lg:text-sm" v-if="messageGroup.type === 'action'">
      <button class="px-4 py-1.5 border cursor-pointer border-primary-light rounded-lg text-primary-main hover:text-white hover:bg-primary-main hover:border-primary-main" @click="runAction(messageGroup.actionData)">
        {{getActionLabel(messageGroup.actionData)}}
      </button>
    </div>

    <div v-if="messageGroup.direction == 'outgoing'">
      <div class="flex gap-3 lg:gap-5 justify-end text-right">
        <div class="flex-grow">
          <div class="flex items-center justify-end gap-2">
            <span class="text-xs lg:text-sm text-primary-muted">{{getDateTime(messageGroup.timestamp)}}</span>
            <span class="font-bold">{{userName}}</span>
          </div>
          <div class="mt-2 text-sm">
            <div class="justify-end inline-block" v-for="image in messageGroup.images" :key="image">
              <div class="h-20 justify-end lg:h-20 bg-primary-medium rounded-xl overflow-hidden flex">
                <Image :src="image" alt="" preview />
              </div>
            </div>
          </div>
          <div class="mt-2 text-sm">
            <div class="justify-center inline-block" v-if="messageGroup.file">
              <div class="p-3 lg:p-4 inline-block bg-primary-light rounded-xl rounded-tr-none text-white">
                <a :href="messageGroup.file.fileUrl">{{messageGroup.file.fileName}}</a>
              </div>
            </div>
          </div>
          <div class="mt-2 text-sm">
            <div class="p-3 lg:p-4 inline-block bg-primary-main rounded-xl rounded-tr-none text-white justify-end text-left "
              v-for="message in messageGroup.messages" :key="message.id">
              <MarkdownRenderer :source="processContent(message.content)" />
            </div>
          </div>
        </div>
        <div class="w-10 lg:w-12 flex-shrink-0">
          <div class="relative w-10 h-10 lg:w-12 lg:h-12 bg-primary-medium rounded-xl overflow-hidden flex items-center justify-center">
            <img class="w-full h-full block object-cover" src="../../tmp/avatar.png" srcset="../../tmp/avatar.png 1x, ../../tmp/avatar@2x.png 2x" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Dropdown, Tooltip, Menu, vTooltip,
} from 'floating-vue';
import Image from 'primevue/image';
import MarkdownRenderer from '../MarkdownRenderer.vue';
import { likeMessage } from '../../api';

export default {
  data() {
    return {
      copied: false,
      showTooltip: false,
      liked: this.messageGroup.likes && this.messageGroup.likes.length > 0,
    };
  },
  components: {
    MarkdownRenderer,
    Image,
  },
  props: {
    messageGroup: Object,
    userName: String,
    topicLabel: String,
    action: String,
    isLast: Boolean,
  },
  mounted() {
    console.log(this.messageGroup);
  },
  methods: {
    async likeMessage(messagesGroup) {
      // send like to server
      await likeMessage(messagesGroup._id);
      this.liked = !this.liked;
    },
    onLoadedMetadata(event) {
      const player = event.target;
      player.volume = 0.5;
    },
    processContent(content) {
      // if it is not a string return empty string
      if (typeof content !== 'string') {
        return '';
      }
      return content;
    },

    getDateTime(date) {
      // return time if today, else return date and time
      const today = new Date();
      const messageDate = new Date(date);
      if (today.toDateString() === messageDate.toDateString()) {
        return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }
      return messageDate.toLocaleString();
    },
    getActionText(action) {
      const translation = this.$t(`chat.actions.${action}`);
      console.log(translation);
      return ` ${translation}`;
    },
    copyMessage(messages) {
      const message = messages.map((msg) => msg.content).join('\n');
      navigator.clipboard.writeText(message);
      // show success checkmark
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    getActionLabel(messageGroup) {
      if (messageGroup.action === 'topic') {
        return `${this.$t('chat.navigate.topic')} ${messageGroup.topicName}`;
      }
      return this.$t(`chat.navigate.${messageGroup.action}`);
    },
    runAction(messageGroup) {
      switch (messageGroup.action) {
        case 'profile':
          return this.$router.push({ name: 'profile' });
        case 'news':
          return this.$router.push({ name: 'news' });
        case 'signals':
          return this.$router.push({ name: 'signals' });
        case 'calendar':
          return this.$router.push({ name: 'calendar' });
        case 'topic':
          return this.$router.push({ path: `/room/${messageGroup.topicId}` });
        case 'upgrade_plan':
        default:
      }
    },
  },
};
</script>

<style scoped>
.message-group {
  margin-bottom: 1em;
}

.markdown-body {
  background-color: rgb(38 36 80 / var(--tw-bg-opacity)) !important;
}

audio::-webkit-media-controls-panel {
  background-color: rgb(38 36 80 / var(--tw-bg-opacity)) !important;
}

audio::-webkit-media-controls-volume-slider {
  background-color: rgb(38 36 80 / var(--tw-bg-opacity)) !important;
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

.message {
  display: flex;
  align-items: center;
}

.message-content {
  padding: 0.5em 1em;
  border-radius: 0.5em;
}

.w-max-800px {
  max-width: 853px;
}

</style>
