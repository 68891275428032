<template>
  <div class="flex-grow">
    <div class="flex flex-col lg:flex-row gap-3 lg:gap-7 flex-grow" v-if="loading">
      <Loader/>
    </div>
    <div v-if="!loading">
      <div class="flex flex-col lg:flex-row gap-3 lg:gap-7 flex-grow">
        <div class="flex-grow">
          <div class="flex flex-wrap items-center justify-between gap-1">
            <h1 class="py-1 my-px font-bold">Signály</h1>
          </div>
          <div class="mt-4 lg:mt-5 flex flex-col gap-1">
            <SignalComponent v-for="(signal, index) in signals" :key="index" :signal="signal" />
            <div
              v-if="signals.length === 0"
              class=" p-2 lg:p-4 border border-primary-light rounded-xl text-center">
              No Signals found
          </div>
          </div>
          <div
          v-if="signals.length > 0"
          class="flex justify-center mt-4" >
            <button class="px-4 py-2 border border-primary-main rounded-lg text-primary-main hover:bg-primary-main hover:text-white" @click="loadMore">Load more</button>
          </div>
        </div>
        <WatchersComp/>
      </div>

      </div>
  </div>

</template>
<script>
import * as api from '../api';
import SignalComponent from '../components/signal/SignalComp.vue';
import Loader from '../components/LoaderComp.vue';
import WatchersComp from '../components/WatchersComp.vue';

export default {
  name: 'SignalsScreen',
  created() {
    // fetch Signals
    this.fetchSignals();
  },
  components: {
    SignalComponent,
    Loader,
    WatchersComp,
  },
  data() {
    return {
      signals: [],
      page: 1,
      limit: 6,
      loading: true,
    };
  },
  methods: {
    async fetchSignals() {
      this.signals = await api.getSignals(this.page, this.limit);
      console.log(this.signals);
      this.loading = false;
    },
    async loadMore() {
      this.page += 1;

      this.signals = [...this.signals, ...await api.getSignals(this.page, this.limit)];
    },
  },
};
</script>
