<template>
  <div class="flex-grow w-max-1004px" v-if="topic">
    <div class="py-3.5 flex items-center justify-between">
      <h1 class="py-1 my-px font-bold">{{ topic.label }}</h1>
      <div class="text-right"  v-if='topic && topic.functions && topic.functions.length >0'>
        <!-- switch button show Advanced -->
        <SelectButton :allowEmpty="false" v-model="filter" :options="filterOptions" optionLabel="label" optionValue="value" class="my-style"
        />
      </div>
      </div>
    <div class="mt-4 flex flex-col rounded-xl">
      <!-- <Loader v-if="isLoadingMessages" /> -->
        <ChatBoard
        v-if="filter === 'chat'"
        :topic="topic"
        :id="id"
        :type="type"
        />
        <AdvancedBoard
        v-if="filter === 'advanced'"
        :topic="topic"
        />
    </div>
  </div>
</template>

<script>
import SelectButton from 'primevue/selectbutton';
import ChatBoard from '../components/chat/ChatBoard.vue';
import Loader from '../components/LoaderComp.vue';
import { getTopic } from '../api';
import AdvancedBoard from '../components/advanced/AdvancedBoard.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    question: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      topic: null,
      filter: 'chat',
      filterOptions: [
        { label: 'Chat', value: 'chat' },
        { label: 'Advanced', value: 'advanced' },
      ],
    };
  },
  components: {
    ChatBoard,
    SelectButton,
    AdvancedBoard,
  },
  mounted() {
    this.fetchTopic();
  },
  methods: {
    async fetchTopic() {
      console.log(this.type);
      if (this.type === 'guru') {
        this.topic = {
          _id: this.id,
          label: 'Berry',
        };
      } else {
        try {
          this.topic = await getTopic(this.id);
          console.log(this.topic);
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.slider{
  background: #fff;
}
.my-style{
  background: rgb(38 36 80 / var(--tw-bg-opacity));
}
.p-togglebutton{
  background: rgb(38 36 80 / var(--tw-bg-opacity)) !important;
  border-color: rgb(38 36 80 / var(--tw-bg-opacity)) !important;
}
.p-togglebutton.p-togglebutton-checked::before {
  background: rgb(149 55 248 / var(--tw-bg-opacity)) !important;
}
.w-max-1004px {
  max-width: 1004px;
}
</style>
<!--
.bg-primary-light {
  --tw-bg-opacity: 1;
  background-color: rgb(83 80 130 / var(--tw-bg-opacity));
}

.bg-primary-main {
  --tw-bg-opacity: 1;
  background-color: rgb(149 55 248 / var(--tw-bg-opacity));
}

.bg-primary-medium {
  --tw-bg-opacity: 1;
  background-color: rgb(38 36 80 / var(--tw-bg-opacity));
}

.bg-primary-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(123 120 170 / var(--tw-bg-opacity));
} -->
