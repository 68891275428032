import _ from 'lodash';
import config from './config';

export async function waitFor(ms = 500) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

// See: https://stackoverflow.com/a/61196133/1521021
export function getDefaultLanguage() {
  const language = navigator.userLanguage
    || (
      navigator.languages
      && navigator.languages.length
      && navigator.languages[0]
    )
    || navigator.language
    || navigator.browserLanguage
    || navigator.systemLanguage
    || config.DEFAULT_LANGUAGE;

  return language.substring(0, 2);
}

export function getSortedCoins(coins, preferredOrder) {
  return _.sortBy(coins, (coin) => {
    const index = preferredOrder.findIndex((c) => c.toLowerCase() === coin.toLowerCase());
    return index === -1 ? Infinity : index;
  });
}

export function paginate(totalItems, currentPage, pageSize, maxPages) {
  if (currentPage === undefined) { currentPage = 1; }
  if (pageSize === undefined) { pageSize = 10; }
  if (maxPages === undefined) { maxPages = 10; }
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);
  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }
  let startPage; let
    endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }
  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array((endPage + 1) - startPage).keys()).map((i) => startPage + i);
  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
  };
}

export async function emailSignIn(store, router, user) {
  try {
    // {
    //   email: this.email,
    //   password: this.pass,
    //   remember: this.remember,
    // }
    await store.dispatch(
      'emailSignIn',
      {
        email: user.email,
        password: user.password,
      },
    );
  } catch (error) {
    // in case user is disabled route to /blocked
    if (error.code === 'auth/user-disabled') {
      await router.replace({ name: 'blocked' });
    }
    throw error;
  }
}

export async function changePassword(store, data) {
  await store.dispatch('changePassword', {
    oldPassword: data.old,
    newPassword: data.new,
  });
}

export async function googleSignIn(store, router) {
  try {
    await store.dispatch('googleSignIn');
  } catch (error) {
    // in case user is disabled route to /blocked
    if (error.code === 'auth/user-disabled') {
      await router.replace({ name: 'blocked' });
    }
    throw error;
  }
}

export async function appleSignIn(store, router) {
  try {
    await store.dispatch('appleSignIn');
  } catch (error) {
    // in case user is disabled route to /blocked
    if (error.code === 'auth/user-disabled') {
      await router.replace({ name: 'blocked' });
    }
  }
}

// Returns true if atm has all data for enabling withdrawals (does not include checks for demo and passive)
export function atmHasAllData(atm) {
  return atm.exchangeRatesBuy && atm.exchangeRatesSell && atm.amountsToWithdraw && atm.banknotes && Object.keys(atm.banknotes).length > 0;
}
