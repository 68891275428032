import { io } from 'socket.io-client';
import * as auth from './auth';

const socketBaseUrl = import.meta.env.VITE_SOCKET_IO_BASE_URL;

class SocketManager {
  constructor() {
    if (!SocketManager.instance) {
      this._socket = null;
      this._eventHandlers = {};
      this._reconnectAttempts = 0;
      this._maxReconnectAttempts = 5;
      this._reconnectDelay = 1000; // Initial delay of 1 second
      SocketManager.instance = this;
    }

    return SocketManager.instance;
  }

  async connect() {
    console.log('Connecting to socket...');
    this._disconnectCurrentSocket();
    console.log('Getting user token...');
    const token = await auth.getUserToken(true);
    this._socket = io(socketBaseUrl, {
      auth: { token },
      transports: ['websocket'],
      forceNew: true,
    });

    this._socket.on('auth_error', (data) => {
      console.error('Connection error:', data);
      this.handleAuthError();
    });

    this._socket.on('connect_error', (data) => {
      console.error('Connection error:', data);
      this._handleReconnect();
    });

    this._socket.on('disconnect', () => {
      console.log('Socket disconnected.');
    });

    this._socket.on('connect', () => {
      console.log('Connected to socket.');
      this._reconnectAttempts = 0; // Reset reconnect attempts on successful connection
    });

    this._socket.connect();
    this._rebindEventHandlers();
  }

  _handleReconnect() {
    if (this._reconnectAttempts < this._maxReconnectAttempts) {
      this._reconnectAttempts += 1;
      const delay = this._reconnectDelay * this._reconnectAttempts; // Exponential backoff
      console.log(`Reconnecting in ${delay / 1000} seconds...`);
      setTimeout(() => {
        this.connect();
      }, delay);
    } else {
      console.error('Max reconnect attempts reached. Giving up.');
    }
  }

  addEventListener(eventName, handler) {
    if (!this._eventHandlers[eventName]) {
      this._eventHandlers[eventName] = [];
    }
    this._eventHandlers[eventName].push(handler);
    this._socket?.on(eventName, (data) => {
      this._eventHandlers[eventName].forEach((h) => h(data));
    });
  }

  removeEventListener(eventName, handler) {
    if (this._eventHandlers[eventName]) {
      this._eventHandlers[eventName] = this._eventHandlers[eventName].filter((h) => h !== handler);
      if (this._eventHandlers[eventName].length === 0) {
        this._socket?.off(eventName);
      }
    }
  }

  emit(event, data) {
    console.log(event, data);
    console.log(this._socket);
    this._socket?.emit(event, data);
  }

  disconnect() {
    this._disconnectCurrentSocket();
  }

  _disconnectCurrentSocket() {
    if (this._socket) {
      console.log('Disconnecting socket...');
      if (this._socket.connected) {
        this._socket.disconnect();
      }
      this._socket.removeAllListeners();
      this._socket = null;
      this._eventHandlers = {};
    }
  }

  async handleAuthError() {
    console.log('Handling authentication error, attempting to reconnect...');
    await this.connect();
  }

  _rebindEventHandlers() {
    for (const [eventName, handlers] of Object.entries(this._eventHandlers)) {
      handlers.forEach((handler) => {
        this._socket?.on(eventName, (data) => handler(data));
      });
    }
  }
}

const instance = new SocketManager();

export default instance;
